import axios from 'axios';
import { TERMS_OF_USE } from '../../constants/api';


/**
 * Function to get terms url
 */
export const getTermsUrlApi = () => {
  return axios.get(TERMS_OF_USE)
};
