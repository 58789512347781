import axios from 'axios';
import { GET_MYSITE_LIST, SAVEMYSITESTATE } from '../../constants';

/**
 * Function to get site cards
 */
export const getMySitesApi = (userId, clientId) =>
  axios.get(`${GET_MYSITE_LIST}?client=${clientId}&user_id=${userId}`);

/**
* Function to update site cards
*/
export const updateMySitesApi = (payload, userId, clientId) =>
  axios.post(`${SAVEMYSITESTATE}?client=${clientId}&user_id=${userId}`, payload);
