import axios from 'axios';
import {
  START_SESSION, GET_USER_DETAIL, SAVE_USER_DETAIL, UPLOAD_PROFILE_PIC
} from '../../constants';

/**
 * Function to get user id
 * @param {Object}  payload
 */
export const startSessionApi = (payload, token) => axios.get(START_SESSION, { params: payload }, token);

/**
 * Function to get user detail
 * @param {integer}  id
 */
export const getUserDetailApi = id => axios.get(`${GET_USER_DETAIL}?user_id=${id}`);

/**
 * Function to update user detail
 * @param {integer}  id
 */
export const updateUserDetailApi = (userId, payload) =>
  axios.post(`${SAVE_USER_DETAIL}?user_id=${userId}`, payload);

/**
* Function to upload profile pic
* @param {integer}  id
*/
export const uploadProfilePicApi = (userId, payload) =>
  axios.post(`${UPLOAD_PROFILE_PIC}?user_id=${userId}`, payload);
