import {
  getIntelligenceApi,
  updateIntelApi,
  getTableauTicketApi
} from '../xhrCalls';
import { beginAjaxCall, endAjaxCall } from './xhrSatus';
import { INTELLIGENCE } from './actionType';

/**
 * Action for get intelligence list
 */
export const getIntelligence = (userId, clientId, loader = true) => dispatch => {
  loader && dispatch(beginAjaxCall())
  return getIntelligenceApi(userId, clientId)
    .then(response => {
      loader && dispatch(endAjaxCall())
      dispatch({
        type: INTELLIGENCE,
        payload: response.data.data
      });
      return response;
    })
    .catch(error => {
      loader && dispatch(endAjaxCall())
      console.error(error);
    });
}

export const setIntelCards = cards => dispatch =>
  dispatch({
    type: INTELLIGENCE,
    payload: cards
  });

/**
 * Action for updating intelligence list
 */
export const updateIntelligence = (payload, userId, clientId) => () =>
  updateIntelApi(payload, userId, clientId)
    .then(response => response)
    .catch(error => {
      console.error(error);
      return error;
    });

/**
 * Action for get intelligence list
 */
export const getTableauTicket = (payload, userId, clientId) => dispatch =>
  getTableauTicketApi(payload, userId, clientId)
    .then(response => response)
    .catch(error => {
      console.error(error);
      return error;
    });

