import React from 'react';

export default function InformationTile({ title, value }) {
  return (
    <div className="col-sm-40 pl-0 pr-0">
      <p className="static-label">{title}</p>
      <p className="static-label-content">{value ? value : "-"}</p>
    </div>
  )
}
