import axios from 'axios';
import { OKTA_USERS, CHANGE_PASSWORD, OKTA_TOKEN } from '../../constants';
import { idToken } from '../../utils';

/**
 * Function to change user's password
 */
export const changePassword = payload =>
  axios.post(
    `${OKTA_USERS}${idToken(OKTA_TOKEN).claims.sub}${CHANGE_PASSWORD}`,
    payload,
    {
      params: { isOkta: true }
    }
  );
