import { toggleBookmarkApi } from '../xhrCalls';
import { beginCardLoader, endCardLoader } from './xhrSatus';
import { toast } from './toast';
import { UNBOOKMARKED_TEXT } from '../../utils';
/**
 * Action for get unbookmark card
 */
export const toggleBookmark = (params, id) => dispatch => {
  dispatch(beginCardLoader(id));
  return toggleBookmarkApi(params)
    .then(response => {
      dispatch(endCardLoader());
      id && dispatch(toast(UNBOOKMARKED_TEXT, true));
      return response;
    })
    .catch(error => {
      dispatch(endCardLoader());
      console.error(error);
      return error;
    });
};
