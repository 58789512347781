import React, { useState } from 'react';

import { connect } from 'react-redux';

import { shareCard } from '../../redux/actions';
import { ModalView } from './ModalView';
import { MultiSelect } from './formElements';
import { ScreenLoader } from './ScreenLoader';

const sharePayload = (shareUsers, userDetail, cardDetail) => {
  const clientCode = sessionStorage.getItem('clientCode');
  const payload = { cards: [] };
  payload.cards = shareUsers.map(user => ({
    from_user_id: userDetail.userid,
    to_user_id: user.value,
    from_user_email: userDetail.email,
    to_user_email: user.email,
    content_id: cardDetail.content_id,
    client: clientCode,
    card_type: cardDetail.card_type
  }));
  return payload;
};

const suggestionValues = list =>
  list &&
  list.map(element => ({
    label: element.firstname || element.label,
    value: element.value,
    email: element.email
  }));

const Footer = ({ isSelectAll, handleSubmit, handleSelectAll, shareUsers, loading }) => (
  <div className="action-btn d-flex justify-content-between align-items-center w-100">
    <span className="custom-checkbox-ui">

      {/* *  Send to all users is move to second phase */}
      <label className="w-100 h-100 d-none">
        <input type="checkbox" checked={isSelectAll} />
        <span className="text" onClick={() => handleSelectAll(!isSelectAll)}>
          Send to all users
        </span>
      </label>
    </span>
    <div className="action-btn-loader globel-btn-loader">
      <button
        type="button"
        className="btn primary modal-primary-btn"
        onClick={() => handleSubmit()}
      >
        Done
      </button>
      {shareUsers && !!shareUsers.length && loading.buttonLoader && <ScreenLoader />}
    </div>
  </div>
);

function Share(props) {
  const {
    isOpen,
    toggleModel,
    shareUserList,
    userDetail,
    cardDetail,
    shareCard,
    loading,
    userId,
    clientCode
  } = props;
  const [shareUsers, setShareUsers] = useState([]);
  const [isSelectAll, setSelectAll] = useState(false);
  const [obj, setObject] = useState({ addFocus: false, error: '' });

  const user = userId || sessionStorage.getItem('userId');
  const client = clientCode || sessionStorage.getItem('clientCode');

  function onFocus(value) {
    const state = { ...obj };
    state.addFocus = value;
    setObject(state);
  }

  function onChange(user) {
    const arrayObj = suggestionValues(user);
    setShareUsers(arrayObj);
    if ((arrayObj && arrayObj.length) === shareUserList.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    onFocus(true);
  }

  function handleClose() {
    setShareUsers([]);
    setSelectAll(false);
    setObject({ error: '' });
    toggleModel();
  }

  async function handleSubmit() {
    if ((shareUsers && shareUsers.length === 0) || (shareUsers === null)) {
      setObject({ error: 'Please select user.' });
    } else {
      const payload =
        shareUsers && sharePayload(shareUsers, userDetail, cardDetail);
      const response = await shareCard(payload, user, client);
      if (Object.keys(response).length > 0) {
        setObject({ error: '' });
        handleClose();
      }
    }
  }

  function handleSelectAll(value) {
    setSelectAll(value);
    if (value) {
      const arrayObj = suggestionValues(shareUserList);
      setShareUsers(arrayObj);
    } else {
      setShareUsers([]);
    }
  }
  return (
    <ModalView
      modelClass="modal-dialog-centered modal-md-custom"
      toggle={handleClose}
      bodyClass="modal-md-custom-body"
      isOpen={isOpen}
      heading="Share with users"
      isShare
      footer={
        <Footer
          isSelectAll={isSelectAll}
          handleSubmit={handleSubmit}
          handleSelectAll={handleSelectAll}
          shareUsers={shareUsers}
          loading={loading}
        />
      }
    >
      <div
        className={`form-group mb-5 select-box--custom floating-select-box ${obj.addFocus &&
          'focus'}`}
      >
        {shareUsers && !shareUsers.length && loading.buttonLoader && <ScreenLoader />}
        <MultiSelect
          options={shareUserList}
          placeholder="Enter Name"
          onChange={onChange}
          value={shareUsers}
          onFocus={onFocus}
          error={obj.error}
        />
      </div>
    </ModalView>
  );
}

const mapStateToProps = state => ({
  shareUserList: state.shareUsers,
  userDetail: state.userDetails,
  cardDetail: state.toggleShareModel,
  loading: state.buttonAjaxStatus,
  userId: state.userId,
  clientCode: state.clientInfo.clientCode
});

export default connect(
  mapStateToProps,
  { shareCard }
)(Share);
