import React from 'react';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';

import '../../assets/styles/index.scss';
import Routes from '../../routes';
import './App.css';

function App(props) {
    return (
      <Router>
        <Routes {...props} />
      </Router>
    );
}

export default withRouter(App);
