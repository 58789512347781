import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getTableauTicket } from '../../redux/actions';
import CardActions from './cardActionFullView';
import { Analytics } from './Analytics';
import TablueReport from './TablueReport';
import { CardMedia } from './CardsMedia';
import CardInfo from './CardInfo';
import Actiondetail from '../actions/Actiondetail';
import { ModalView } from '../layout/ModalView';
import { getMobileOperatingSystem } from '../../utils';

const sitedetails = (props, cardData) => {
  const { type, mySite, updateSize, handleBookmark, from_user_email } = props;
  return (
    <div className="col-sm-12 card-shadow">
      <div className="row mr-0 ml-0 medium-card-media-row">
        <div className="col-md-3 pl-0 pr-0">
          <CardMedia
            card={cardData}
            type={type}
            updateSize={updateSize}
            handleBookmark={handleBookmark}
            isEnable
            from_user_email={from_user_email}
          />
        </div>
        <div className="col-md-9 pl-0 pr-0">
          <div className="row">
            <div className="col-md-5 col-lg-4">
              <div className="card-body pt-1">
                <h5 className="card-title">{cardData.address1}</h5>
                <p className="card-text">
                  {`${cardData.address1}, ${cardData.city} ${cardData.region || ''}`}{' '}
                </p>
              </div>
              <div className="divider" />
              <CardInfo card={cardData} />
            </div>
            <div className="col-md-7 col-lg-8">
              <div className="media-action justify-content-end pt-1 pb-0">
                <CardActions
                  card={cardData}
                  className="action dropdown desktop-view"
                  type={type}
                  {...props}
                />
              </div>
              <Analytics analytics={mySite[0]} type={type} isEnable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const RenderActiondetail = ({
  handleBookmark,
  isHome,
  share_card_id,
  handleDelete
}) => (
    <Actiondetail
      handleBookmark={handleBookmark}
      handleDelete={handleDelete}
      isHome={isHome}
      share_card_id={share_card_id}
    />
  );
class FullViewCard extends PureComponent {
  render() {
    const {
      isOpen,
      toggleModel,
      mySite,
      handleBookmark,
      isHome,
      share_card_id,
      handleDelete,
      isAction
    } = this.props;
    const cardData = mySite[0] && mySite[0].data1[0];
    const isMobile = getMobileOperatingSystem();
    return (
      <ModalView
        modelClass="full-screen-model"
        toggle={toggleModel}
        bodyClass="full-screen-model-body"
        isOpen={isOpen}
      >
        {cardData && !isAction ? (
          <div className="grid-view">
            <div className="row">
              <div className="card-view full-screen-card">
                <div className="card">
                  <div className="row-1 large-card-with-energy-data">
                    {cardData.facilitysk &&
                      sitedetails({ ...this.props }, cardData)}
                    <TablueReport
                      card={cardData}
                      share_card_id={share_card_id}
                      analytics={mySite[0]}
                      handleBookmark={handleBookmark}
                      handleDelete={handleDelete}
                      isEnable
                      isMobileOrTab={isMobile}
                      isHome={isHome}
                      className={`col-sm-12 energy-block ${cardData.platformcontentsk &&
                        'intel'}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
            <RenderActiondetail
              handleBookmark={handleBookmark}
              isHome={isHome}
              handleDelete={handleDelete}
              share_card_id={share_card_id}
            />
          )}
      </ModalView>
    );
  }
}
const mapStateToProps = state => ({
  mySite: state.siteDetail,
  userId: state.userId,
  clientCode: state.clientInfo.clientCode,
  from_user_email: state.toggleSiteModel.from_user_email,
});

export default connect(
  mapStateToProps,
  { getTableauTicket }
)(FullViewCard);
