import React from "react";

export function ScreenLoader() {
  return (
      <div className="loading-dots">
        <div className="loading-dots--dot" />
        <div className="loading-dots--dot" />
        <div className="loading-dots--dot" />
      </div>
  );
}
