import React from 'react';

import { template } from '@babel/core';
import { ScreenLoader } from '../layout/ScreenLoader';

export function Analytics({ analytics, isEnable }) {
  if (analytics === undefined) {
    return <ScreenLoader />;
  }

  const { data2 } = analytics;

  if (data2[0] && data2[0].facilitysk && data2[0].formattedvalue === undefined) {
    return false;
  }

  return (
    <div className="portfolio-product-widgets">
      <div className="row">
        {data2.map((item, i) => (
          <div
            key={`${template.unit}-${i}`}
            className={` ${isEnable ? 'col-6 col-lg-4' : 'col-6 col-sm-4'}`}
          >
            <div className="card flex-row">
              {
                <div
                  className={` ${!data2[0].facilitysk && 'small-icon'} widgets-type icon icon-cortex`}
                  style={{
                    backgroundImage: `url(${item.image})`
                  }}
                />
              }
              <div className="widgets-info ">
                {data2[0].facilitysk && (
                  <p className="widgets-data">
                    {item.formattedvalue}
                    <em>{template.unit}</em>
                  </p>
                )}
                <p className="widgets-title">{item.title || item.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
