import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import FloatingLabelInput from './FloatingLabel';
import UploadImage from './UploadImage';
import ProfileSetting from './ProfileSetting';
import {
  updateUserDetail,
  getUserDetail,
  uploadProfilePic,
  removedProfilePic
} from '../../redux/actions';
import { phoneNumberValidation, REQUIRED } from '../../utils';
import { ScreenLoader } from '../layout/ScreenLoader';

const FormFields = ({ userDetail, profile, handleOnChange, error, profilePic }) => (
  <>
    <FloatingLabelInput
      id="email"
      label="Email Address"
      className="col-sm-6"
      defaultValue={' '}
      readOnly
      handleOnChange={event => handleOnChange(event)}
      value={userDetail.email}
    />
    <FloatingLabelInput
      className="col-sm-6"
      id="company"
      label="Company Name"
      defaultValue={' '}
      readOnly
      handleOnChange={event => handleOnChange(event)}
      value={userDetail.company}
    />
    <FloatingLabelInput
      id="fullname"
      label="Full Name"
      className="col-sm-6"
      defaultValue={' '}
      handleOnChange={event => handleOnChange(event)}
      error={error}
      value={
        profile.fullname !== undefined
          ? profile.fullname
          : userDetail.fullname.trim()
      }
    />
    <FloatingLabelInput
      id="position"
      label="Position"
      className="col-sm-6"
      defaultValue={' '}
      readOnly
      handleOnChange={event => handleOnChange(event)}
      value={
        profile.position !== undefined ? profile.position : userDetail.position
      }
    />
    <FloatingLabelInput
      id="title"
      label="Title (optional)"
      className="col-sm-6"
      defaultValue={' '}
      handleOnChange={event => handleOnChange(event)}
      value={profile.title !== undefined ? profile.title : userDetail.title}
    />
    <FloatingLabelInput
      id="address"
      label="Address (optional)"
      className="col-sm-6"
      defaultValue={' '}
      handleOnChange={event => handleOnChange(event)}
      value={
        profile.address !== undefined ? profile.address : userDetail.address
      }
    />
    <FloatingLabelInput
      id="phonemobile"
      label="Phone Number (optional)"
      className="col-sm-6"
      defaultValue={' '}
      handleOnChange={event => handleOnChange(event)}
      value={
        profile.phonemobile !== undefined
          ? profile.phonemobile
          : userDetail.phonemobile
      }
    />
  </>
);

function MyProfile({
  isOpen,
  toggleModel,
  userDetail,
  profilePic,
  logout,
  updateUserDetail,
  getUserDetail,
  loading,
  uploadProfilePic,
  removedProfilePic
}) {
  const [profile, setProfile] = useState({});
  const [isChanged, handleTextChange] = useState({});
  const [error, setError] = useState('');
  const [loadingPic, setLoadingPic] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [removeClass, setRemoveClass] = useState('');

  const userId = sessionStorage.getItem('userId');

  function handleOnChange(event) {
    const { id, value, result } = event && event.target;
    let temp = value;
    const state = { ...profile };
    state.isSave = false;
    handleTextChange(true);
    switch (id) {
      case 'title':
      case 'address':
        if (value === ' ') {
          return false;
        }
        state[id] = value;
        break;
      case 'fullname':
        const nameArr = value.split(' ');
        if (nameArr.length < 3) {
          state[id] = value;
        }
        break;
      case 'phonemobile':
        if (
          (phoneNumberValidation(value) && value.length < 13) ||
          value.length === 0
        ) {
          if (temp.length === 4 || temp.length === 8) {
            temp = temp.slice(0, -2);
            state[id] = temp;
          } else {
            if (temp[temp.length - 1] === '-') {
              return false;
            }
            state[id] =
              temp.length === 3 || temp.length === 7 ? temp + '-' : temp;
          }
        }
        break;
      default:
    }
    if (result) {
      state.image = result;
    }
    setProfile(state);
    return true;
  }

  async function handleSave() {
    if (profile.fullname === '' || profile.fullname) {
      if (profile.fullname.trim().length < 1 || profile.fullname === '') {
        return setError(REQUIRED);
      } else {
        const name = profile.fullname.trim().split(' ');
        profile.firstname = name[0];
        profile.lastname = (name.length !== 1 && name[name.length - 1]) || ' ';
        setError('');
      }
    }
    if (!error) {
      const payload = {
        profile: {
          okta_id: userDetail.okta_id,
          Email: userDetail.email,
          Company: userDetail.company,
          FullName:
            profile.fullname !== undefined
              ? profile.fullname.trim()
              : userDetail.fullname,
          Position: userDetail.position,
          Image: profile.image !== undefined ? profile.image : userDetail.image,
          Title:
            profile.title !== undefined
              ? profile.title.trim()
              : userDetail.title || '',
          Address:
            profile.address !== undefined
              ? profile.address.trim()
              : userDetail.address || '',
          PhoneMobile:
            profile.phonemobile !== undefined
              ? profile.phonemobile.trim()
              : userDetail.phonemobile,
          FirstName:
            profile.fullname !== undefined
              ? profile.firstname
              : userDetail.firstname,
          LastName:
            profile.fullname !== undefined
              ? profile.lastname
              : userDetail.lastname,
          PhoneWork: '',
          PhoneOther: ''
        }
      };
      const count = Object.keys(profile);
      if (count.length > 0 && isChanged) {
        updateUserDetail(userId, payload);
        handleTextChange(false);
        const state = { ...profile };
        state.isSave = true;
        setProfile(state);
        onCloseModel(true);
      }
    }
    return true;
  }

  function onCloseModel(value) {
    setError('');
    !value && setProfile({});
    getUserDetail(userId);
    toggleModel('showMyProfile', value);
  }

  if (Object.keys(userDetail).length < 1) {
    return false;
  }

  async function handleUploadImage(event) {
    setRemoveClass('remove-disabled');
    setLoadingPic(true);
    setButtonDisabled(true);
    handleOnChange(event);
    const data = new FormData();
    const imagedata = document.querySelector('input[type="file"]').files[0];
    data.append('file', imagedata);
    await uploadProfilePic(userId, data).then(() => {
      setRemoveClass('');
      setLoadingPic(false);
      setButtonDisabled(false);
    });
  }

  async function handleRemoveImage(event) {
    setRemoveClass('remove-disabled');
    setLoadingPic(true);
    setButtonDisabled(true);
    handleOnChange(event);
    await removedProfilePic(userId, {}).then(() => {
      setRemoveClass('');
      setLoadingPic(false);
      setButtonDisabled(false);
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => onCloseModel(false)}
      className="open-right-side"
    >
      <ModalHeader toggle={() => onCloseModel(false)}>My Profile</ModalHeader>
      <ModalBody className="profile-page">
        <div className="custom-form">
          <div className="row">
            <UploadImage
              id="image"
              image={profilePic || userDetail.image}
              onUploadImage={event => handleUploadImage(event)}
              onRemoveImage={event => handleRemoveImage(event)}
              loading={loadingPic}
              disabled={buttonDisabled}
              removeClass={removeClass}
            />
            <FormFields
              userDetail={userDetail}
              profile={profile}
              handleOnChange={handleOnChange}
              error={error}
            />
            <div className="col-sm-6 mb-42 d-flex justify-content-end align-items-end">
              <div className="action-btn-loader">
                <button
                  type="button"
                  className={`btn primary w-150 ${(profile.isSave ||
                    Object.keys(profile).length < 1) &&
                    'disabled-ui'}`}
                  onClick={() => handleSave()}
                >
                  Save
                </button>
                {loading.buttonLoader && <ScreenLoader />}
              </div>
            </div>
          </div>
        </div>
        <ProfileSetting logout={logout} toggleModel={toggleModel} />
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = state => ({
  userDetail: state.userDetails,
  profilePic: state.profilePic,
  loading: state.buttonAjaxStatus
});

export default connect(
  mapStateToProps,
  { updateUserDetail, getUserDetail, uploadProfilePic, removedProfilePic }
)(MyProfile);
