import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withAuth } from '@okta/okta-react';
import { connect } from 'react-redux';

import NavItems from './NavItems';
import {
  getTermsUrl,
  getUserDetail,
  startSession,
  getUserClient,
  toggleClient,
  logOut,
  toggleShareModel,
  toggleAddNotesModel
} from '../../redux/actions';
import ReactDropdown from './ReactDropdown';
import { getItem, storageTimeDiff, setStorageTime } from '../../utils';
import { OKTA_TOKEN, DEFAULT_USER_IMAGE_NAV, NUMBER } from '../../constants';
import MyProfile from '../myProfile';
import ChangePassword from '../myProfile/ChangePassword';
import Module from '../myProfile/ModuleModel';
import Share from './Share';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: null,
      dropdownMenu: false,
      showMyProfile: false,
      showModule: false,
      showChangePassword: false,
      label: null,
      mounted: true
    };
    storageTimeDiff(this.props);
  }

  componentWillMount() {
    this.setState({ mounted: true });
    window.addEventListener('beforeunload', this.onUnload);
  }

  async componentDidMount() {
    const { getTermsUrl } = this.props;
    getTermsUrl();
    this.clientName = sessionStorage.getItem('clientName');
    const authenticated = await this.checkAuthentication();
    const { history } = this.props;
    if (
      !authenticated &&
      !history.location.pathname.includes('/signIn') &&
      !history.location.pathname.includes('/implicit/callback')
    ) {
      window.location.href = '/signIn';
    }
    this.getStartSession();
    this.interval = setInterval(setStorageTime, NUMBER.Thousand);
  }

  async componentDidUpdate() {
    await this.checkAuthentication();
    const { mounted, authenticated } = this.state;
    if (mounted && authenticated) {
      this.setState({ mounted: false });
      this.getStartSession();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onUnload = () => {
    setStorageTime();
  };

  checkAuthentication = async () => {
    const { auth } = this.props;
    const { authenticated } = this.state;
    const authenticate = await auth.isAuthenticated();
    if (authenticate !== authenticated) {
      this.setState({ authenticated: authenticate });
    }
    return authenticate;
  };

  getStartSession = () => {
    const { startSession, getUserDetail, getUserClient } = this.props;
    const oktaToken = JSON.parse(localStorage.getItem('okta-token-storage'));
    if (oktaToken && oktaToken.idToken) {
      const payload = {
        email: oktaToken.idToken.claims.email,
        sub: oktaToken.idToken.claims.sub,
        audience: oktaToken.idToken.claims.aud,
        issued: oktaToken.idToken.claims.iat,
        issuer: oktaToken.idToken.issuer,
        idprovider: ''
      };
      setTimeout(async () => {
        let userId = sessionStorage.getItem('userId');
        if (userId) {
          getUserDetail(userId);
          getUserClient({ user_id: userId });
        } else {
          userId = await startSession(payload, oktaToken.idToken);
          getUserClient({ user_id: userId });
          getUserDetail(userId);
        }
      }, 0);
    }
  };

  onSelect = (clientCode, clientName, image) => {
    const { toggleClient } = this.props;
    this.setState({ label: clientName });
    toggleClient({ clientCode, clientLogo: image, clientName });
  };

  logout = async () => {
    const { history, auth, logOut } = this.props;
    this.toggleModel('showMyProfile', false);
    logOut();
    auth.logout('/signIn')
      .catch(function (error) {
        history && history.push('/signIn');
      });
    window.location.replace('/signIn');
  };

  toggleMenu = () => {
    this.setState(prevState => ({
      dropdownMenu: !prevState.dropdownMenu
    }));
  };

  toggleModel = (key, value) => {
    this.setState({ [key]: value });
  };

  onError = event => {
    event.target.src = this.props.userDetail.image;
  };

  beforeLogin = () => (
    <>
      <p className="mb-0 toc text-right">
        <a href={this.props.terms} target="__blank">
          Terms of use
        </a>
        <span className="d-block mb-0 copyright-text">
          Copyright © {new Date().getFullYear()} InSite. All rights reserved.
        </span>
      </p>
    </>
  );

  afterLogin = () => {
    const { client } = this.props;
    const oktaAuth = JSON.parse(getItem(OKTA_TOKEN));
    return (
      <>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <NavItems wrapperClass="navbar-nav menu" itemClass="nav-item" />
          {this.dropdownMenu(oktaAuth, client)}
        </div>
      </>
    );
  };

  dropdownMenu = (oktaAuth, client) => (
    <div className="nav-item dropdown nav-item-right ml-auto">
      <div className="custom-select-box dropdown-custom truncate-value">
        <ReactDropdown
          className="custom-select"
          label={this.state.label || this.clientName || this.props.clientName}
          options={client}
          onSelect={this.onSelect}
        />
      </div>
      <div
        className="profile-dropdown dropdown-custom cursor-pointer"
        onClick={() =>
          this.props.userDetail.email && this.toggleModel('showMyProfile', true)}
      >
        <span className="user-profile-pic">
          <img
            className="profile-picture"
            src={this.props.profilePic || this.props.userDetail.image || DEFAULT_USER_IMAGE_NAV}
            height="24"
            width="24"
            alt="User Profile Pic"
            onError={event => this.onError(event)}
          />
        </span>
        <span className="user-profile-name">
          {this.props.userDetail.fullname}
        </span>
        <span className="icon-down-arrow-white" />
      </div>
    </div>
  );

  render() {
    const { showMyProfile, showChangePassword, showModule } = this.state;
    const {
      isShare: { isShareOpen },
      toggleShareModel
    } = this.props;
    const url = window.location.href;
    if (url.includes('/404')) {
      return false;
    }
    const notLoggedIn =
      this.state.authenticated === null || !this.state.authenticated;
    return (
      <>
        <Share isOpen={isShareOpen} toggleModel={toggleShareModel} />
        <MyProfile
          isOpen={showMyProfile}
          toggleModel={this.toggleModel}
          logout={this.logout}
        />
        <ChangePassword
          isOpen={showChangePassword}
          toggleModel={this.toggleModel}
        />
        <Module isOpen={showModule} toggleModel={this.toggleModel} />
        <nav
          className={`navbar navbar-default
        ${
            notLoggedIn
              ? 'navbar-login'
              : 'navbar-dashboard fixed-top navbar-expand'
            }`}
        >
          <div className="container">
            <Link className="navbar-brand" to="/signIn">
              <span
                className="icon insite-logo"
                style={
                  {
                    /** backgroundImage:
                    clientLogo && clientLogo !== 'null' && `url(${clientLogo})`
                    we will use this in future once API will return logo */
                  }
                }
              >
                InSite Logo
              </span>
            </Link>
            {notLoggedIn ? this.beforeLogin() : this.afterLogin()}
          </div>
        </nav>
      </>
    );
  }
}

const mapStateToProps = state => ({
  terms: state.terms,
  client: state.client,
  user: state.userId,
  clientCode: state.clientInfo.clientCode,
  clientLogo: state.clientInfo.clientLogo,
  clientName: state.clientInfo.clientName,
  isShare: state.toggleShareModel,
  isAddNote: state.toggleAddNotesModel,
  userDetail: state.userDetails,
  profilePic: state.profilePic
});

export default withAuth(
  connect(
    mapStateToProps,
    {
      getTermsUrl,
      getUserDetail,
      startSession,
      getUserClient,
      toggleClient,
      logOut,
      toggleShareModel,
      toggleAddNotesModel
    }
  )(Navbar)
);
