import React, { useState } from 'react';

export function Image({ className, src, alt }) {
  const [isBrokenImage, toggleBrokenImage] = useState(false);
  function onError(event) {
    event.target.src = src;
    toggleBrokenImage(true);
  }

  return (
    <img
      className={className}
      src={src}
      alt={alt}
      onError={event => !isBrokenImage && onError(event)}
    />
  );
}
