import React, { useState } from 'react';

import { connect } from 'react-redux';

import { ModalView } from './ModalView';
import { TextField } from './formElements';
import { writeNote, deleteNote, getNote } from '../../redux/actions';
import { ScreenLoader } from './ScreenLoader';
import { REQUIRED } from '../../utils';

const Footer = ({ hasNote, handleSubmit, handleDelete, loading, isEdit }) => (
  <div className="action-btn d-flex justify-content-between align-items-center w-100">
    <span>
      <a
        href
        className="delete-button text-uppercase"
        onClick={() => handleDelete()}
      >
        {hasNote ? 'Delete Note' : ''}
      </a>
    </span>
    <div className="action-btn-loader globel-btn-loader">
      <button
        type="button"
        className="btn primary modal-primary-btn"
        onClick={() => handleSubmit()}
      >
        {hasNote ? 'Save' : 'Submit'}
      </button>
      {isEdit && loading.buttonLoader && <ScreenLoader />}
    </div>
  </div>
);

function AddNotes(props) {
  const {
    isOpen,
    toggleModel,
    writeNote,
    content_id,
    hasNote,
    deleteNote,
    card_type,
    editNotes,
    loading,
    user,
    client,
    getNote,
    handleNote
  } = props;
  const [notes, setNotes] = useState();
  const [isEdit, setEdit] = useState(false);
  const [obj, setObject] = useState({ addFocus: '', error: false });

  const userId = user || sessionStorage.getItem('userId');
  const clientCode = client || sessionStorage.getItem('clientCode');

  function onChange(event) {
    const { value } = event.target;
    setNotes(value);
    setEdit(true);
  }

  function onChangeValue() {
    if (!isEdit && loading.buttonLoader) {
      return 'Loading...';
    } else if (notes !== undefined) {
      return notes;
    } else {
      return editNotes;
    }
  }

  function getPayload() {
    const params = {
      user_id: userId,
      client: clientCode,
      content_id: content_id,
      card_type: card_type
    };
    return params;
  }

  function handleClose(hasNote) {
    setObject({ error: '' });
    setNotes();
    setEdit(false);
    toggleModel(false, '', '', hasNote);
  }

  function onFocus(value) {
    const state = { ...obj };
    state.addFocus = value;
    setObject(state);
  }

  async function handleSubmit() {
    const payload = {
      notes: [
        {
          client: clientCode,
          card_type: card_type,
          content_id: content_id,
          note: notes || editNotes
        }
      ]
    };
    if (
      (notes === '' || notes === undefined || notes.trim().length < 1) &&
      !editNotes
    ) {
      return setObject({ error: REQUIRED });
    } else if (
      (notes === '' || notes === undefined || notes.trim().length < 1) &&
      editNotes &&
      isEdit
    ) {
      return setObject({ error: REQUIRED });
    } else {
      setEdit(true);
      const response = await writeNote(payload, userId);
      const params = getPayload();
      const key = await getNote(params, 'updated');
      handleNote(content_id, key);
      if (response.data.CardState) {
        handleClose();
        return true;
      }
      return true;
    }
  }

  async function handleDelete() {
    const payload = {
      user_id: userId,
      note_id: hasNote
    };
    await deleteNote(payload, userId);
    const params = getPayload();
    handleNote(content_id, false);
    await getNote(params, 'deleted');
    handleClose();
  }
  return (
    <ModalView
      modelClass="modal-dialog-centered modal-md-custom"
      toggle={() => handleClose(hasNote)}
      bodyClass="modal-md-custom-body"
      isOpen={isOpen}
      heading={hasNote ? 'Edit Note' : 'Add Note'}
      isShare
      footer={
        <Footer
          isEdit={isEdit}
          handleSubmit={handleSubmit}
          hasNote={hasNote}
          handleDelete={handleDelete}
          loading={loading}
        />
      }
    >
      <div className="custom-form">
        <div className={`form-group mt-32 mb-32  ${obj.addFocus && 'focus'}`}>
          <TextField
            name="notes"
            label="Note"
            onChange={onChange}
            value={onChangeValue}
            component="textarea"
            readOnly={!isEdit && loading.buttonLoader}
            onFocus={onFocus}
          />
          <div className="is-invalid">{obj.error}</div>
        </div>
      </div>
    </ModalView>
  );
}
const mapStateToProps = state => ({
  content_id: state.toggleAddNotesModel.content_id,
  card_type: state.toggleAddNotesModel.type,
  editNotes: state.notes,
  loading: state.buttonAjaxStatus,
  hasNote: state.toggleAddNotesModel.hasNote,
  user: state.userId,
  client: state.clientInfo.clientCode
});

export default connect(
  mapStateToProps,
  { writeNote, deleteNote, getNote }
)(AddNotes);
