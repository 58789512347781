import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';

import {
  toggleSiteModel,
  updateMySites,
  getHomeCards,
  getSite,
  toggleBookmark,
  updateHomeCards,
  getActions,
  contentDetail,
  getUnreadActionCount,
  deleteShareCard,
  setHomeCards,
  toggleAddNotesModel,
  actionDetail,
  toast
} from '../../../redux/actions';
import {
  updateOrderPayload,
  SMALL,
  getItem,
  getFirstName,
  updateSizePayload,
  searchInArray,
  insertKeyValue
} from '../../../utils';
import FullViewCard from '../../cards/FullViewCard';
import WelcomeSiteCard from '../../cards/WelcomeCard';
import CardNotFound from '../../cards/CardNotFound';
import { ScreenLoader } from '../../layout/ScreenLoader';
import SortableComponent from '../../cards/Sortable';
import SubNav, {
  SearchForm,
  ActionButton,
  ActionFilter
} from '../../layout/SubNav';
import Actions from '../../actions';
import {
  OKTA_TOKEN,
  WELCOME_HOME_TEXT,
  CARDS_PER_PAGE,
  NO_CARD,
  UNBOOKMARKED_TEXT,
  BOOKMARKED_TEXT
} from '../../../constants';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sitedetails: {},
      actionList: [],
      selectedOption: null,
      searchText: '',
      isSearching: false,
      currentPage: 1,
      bookmarkArray: []
    };
    this.userId = sessionStorage.getItem('userId');
    this.clientCode = sessionStorage.getItem('clientCode');
  }

  componentDidMount() {
    const { clientCode, userId } = this.props;
    this.userId = userId || this.userId;
    this.clientCode = this.clientCode || clientCode;
    if (this.clientCode && this.userId) {
      this.gethomeCardsList(this.userId, this.clientCode);
    }
  }

  componentDidUpdate(prevState) {
    const { clientCode, userId } = this.props;
    if (prevState.clientCode !== clientCode) {
      this.userId = userId || this.userId;
      this.clientCode = clientCode;
      this.setState({
        searchText: '',
      });
      this.gethomeCardsList(this.userId, this.clientCode, false, false, prevState.clientCode && true);
    }
  }

  gethomeCardsList = (userId, clientCode, isAction, isSearching = false, loader = false) => {
    const { getHomeCards } = this.props;
    getHomeCards(userId, clientCode, loader).then(async resp => {
      if (resp && resp.data) {
        const homeCards = resp.data.data;
        this.prepareDetails(homeCards);
        this.setState({ homeCards, isSearching: isSearching });
        const payload = updateOrderPayload(homeCards, userId, clientCode);
        this.updateCard(payload);
      } else {
        this.setState({ homeCards: [] });
      }
    });
    !isAction && this.loadAction();
  };

  setPage = page => this.setState({ currentPage: page });

  loadAction = (page = 1) => {
    const { getActions, getUnreadActionCount } = this.props;
    const { selectedOption } = this.state;
    const payload = { user_id: this.userId, page: page, limit: CARDS_PER_PAGE };
    getActions(payload).then(async resp => {
      if (resp) {
        const { data, current_page, next_page } = resp;
        this.setState({
          actionList: data,
          currentPage: current_page,
          nextPage: next_page
        });
        this.applyFilter(selectedOption);
      } else {
        this.setState({ actionList: [] });
      }
    });
    getUnreadActionCount({ user_id: this.userId });
  };

  onSortEnd = async ({ oldIndex, newIndex }) => {
    const { homeCards } = this.state;
    const updatedCards = arrayMove(homeCards, oldIndex, newIndex);
    const payload = updateOrderPayload(
      updatedCards,
      this.userId,
      this.clientCode,
      true
    );
    this.updateCard(payload);
    this.setState({ homeCards: updatedCards });
    await this.prepareDetails(updatedCards);
  };

  prepareDetails = async list => {
    const { contentDetail, getSite } = this.props;
    const { bookmarkArray } = this.state;
    const arr = {};
    list.map(async item => {
      const id =
        item.facilitysk || item.content_id || item.platformcontentsk || item.id;
      if (item.bookmark && !bookmarkArray.includes(id)) {
        bookmarkArray.push(id);
      }
      new Promise(resolve => {
        if (item.size && item.size.trim() !== SMALL) {
          item.content_id
            ? contentDetail(item.content_id, this.userId, this.clientCode).then(
              res => {
                arr[item.content_id] = res[0];
                resolve(arr);
                this.setState({ sitedetails: {} });
              }
            )
            : getSite(item.facilitysk, this.userId, this.clientCode).then(
              res => {
                arr[item.facilitysk] = res[0];
                resolve(arr);
                this.setState({ sitedetails: {} });
              }
            );
        } else {
          this.setState({ sitedetails: {} });
        }
      }).then(() => {
        this.setState(() => ({ sitedetails: arr }));
      });
    });
  };

  updateCardSize = (index, size) => {
    const { homeCards, sitedetails } = this.state;
    const tempSites = [...homeCards];
    const tempObj = { ...homeCards[index] };
    tempObj.size = size;
    tempSites[index] = tempObj;
    this.setState({ homeCards: tempSites });

    if (
      size === SMALL &&
      sitedetails[tempObj.content_id || tempObj.facilitysk]
    ) {
      delete sitedetails[tempObj.content_id || tempObj.facilitysk];
    }
    const payload = updateSizePayload(
      tempObj,
      this.userId,
      this.clientCode,
      index
    );
    this.updateCard(payload);
    this.pushdetails(
      tempObj.content_id || tempObj.facilitysk,
      tempObj.content_id ? 'intel' : 'site'
    );
  };

  pushdetails = (id, key) => {
    const { contentDetail, getSite } = this.props;
    const { sitedetails } = this.state;
    const arr = { ...sitedetails };
    key === 'intel'
      ? contentDetail(id, this.userId, this.clientCode).then(res => {
        arr[id] = res[0];
        this.setState({ sitedetails: arr });
      })
      : getSite(id, this.userId, this.clientCode).then(res => {
        arr[id] = res[0];
        this.setState({ sitedetails: arr });
      });
  };

  getObject = (cards, id) =>
    cards.find(
      obj =>
        (obj.content_id === id || obj.facilitysk === id || obj.id === id) &&
        !obj.is_shared
    );

  handleBookmark = async card => {
    const { toggleBookmark, setHomeCards } = this.props;
    const { homeCards } = this.state;
    const temp = [...homeCards];
    const id =
      card.facilitysk || card.content_id || card.platformcontentsk || card.id;
    const cardObj = this.getObject(homeCards, id);
    const params = {
      user_id: this.userId,
      content_id: id,
      client: this.clientCode,
      card_type: cardObj.card_type,
      bookmarked: true
    };
    await toggleBookmark(params, id);
    this.loadAction();
    const tempobj = temp.filter(
      k =>
        (k.facilitysk || k.content_id || k.platformcontentsk || k.id) !== id ||
        k.is_shared === 1 ||
        k.is_shared === true
    );
    const tempProps = this.props.homeCards;
    const tempCards = tempProps.filter(
      k =>
        (k.facilitysk || k.content_id || k.platformcontentsk || k.id) !== id ||
        k.is_shared === 1 ||
        k.is_shared === true
    );
    this.setState({ homeCards: tempobj });
    setHomeCards(tempCards);
  };

  handleNote = (cardId, hasNote) => {
    const { homeCards } = this.state;
    const temp = [...homeCards];
    const index = temp.findIndex(
      k =>
        (k.content_id === cardId ||
          k.facilitysk === cardId ||
          k.id === cardId) &&
        !k.is_shared
    );
    const tempobj = { ...temp[index] };
    tempobj.note_id = hasNote;
    temp[index] = tempobj;
    this.setState({ homeCards: temp });
    this.handleActionNote(cardId, hasNote);
  };

  handleActionBookmark = async card => {
    const { actionList } = this.state;
    const { toggleBookmark, toast } = this.props;
    const temp = [...actionList];
    const payload = {
      user_id: this.userId,
      content_id: card.id,
      client: this.clientCode,
      card_type: 'ACTION',
      bookmarked: card.bookmark
    };
    const index = temp.findIndex(k => k.id === card.id && !card.is_shared);
    const tempobj = { ...temp[index] };
    tempobj.bookmark = !card.bookmark;
    temp[index] = tempobj;
    this.setState({ actionList: temp });
    await toggleBookmark(payload);
    this.gethomeCardsList(this.userId, this.clientCode, true);
    if (card.bookmark) {
      toast(UNBOOKMARKED_TEXT, true);
    } else {
      toast(BOOKMARKED_TEXT, true);
    }
  };

  toggleAction = () => {
    this.loadAction();
    this.setState(prevState => ({
      showActions: !prevState.showActions,
      currentPage: 1,
      selectedOption: null
    }));
  };

  handleActionNote = (cardId, hasNote) => {
    const { actionList } = this.state;
    const temp = [...actionList];
    const index = temp.findIndex(k => k.id === cardId);
    const tempobj = { ...temp[index] };
    tempobj.note_id = hasNote;
    temp[index] = tempobj;
    this.setState({ actionList: temp });
  };

  backButton = () => (
    <small className="d-block" onClick={() => this.toggleAction()}>
      <span className="cursor-pointer">
        <img
          width="11"
          height="9"
          className="mr-1"
          src={'assets/images/svgicons/back-arrow.svg'}
          alt="Back Arrow Icon"
        />
        Back
      </span>
    </small>
  );

  onchangeFilter = selected => {
    this.setState({ selectedOption: selected });
    this.applyFilter(selected);
  };

  applyFilter = selected => {
    const {
      actions: { data }
    } = this.props;
    if (selected && selected.value === 'All') {
      this.setState({ actionList: data });
    } else if (selected) {
      const cards = data.filter(obj => obj.priority === selected.value);
      this.setState({ actionList: cards });
    }
  };

  handleChange = async event => {
    const { value, name } = event.target;
    const { homeCards } = this.props;
    const str = value.replace(/^\s+/g, '');
    if (str.length < 1) {
      this.setState({
        homeCards: homeCards,
        [name]: str,
        isSearching: false
      });
      return false;
    }
    const newHomeCards = insertKeyValue(homeCards);
    const result = searchInArray(newHomeCards, str);
    let isSearching = false;
    if (result.length < 1) {
      isSearching = true;
    }
    this.setState({
      homeCards: result,
      [name]: str,
      isSearching: isSearching
    });
    return true;
  };

  handleDelete = async share_card_id => {
    const { deleteShareCard, toggleSiteModel } = this.props;
    await deleteShareCard(share_card_id, this.userId, this.clientCode);
    setTimeout(() => {
      const { homeCards } = this.state;
      let temp = [...homeCards];
      const tempArr = temp.filter(k => k.share_card_id !== share_card_id);
      temp = [...tempArr];
      this.setState({ homeCards: temp });
    }, 500);
    toggleSiteModel(false, '');
  };

  clearSearch = () => {
    this.setState({
      isSearching: false,
      searchText: ''
    });
    this.gethomeCardsList(this.userId, this.clientCode, false, true);
  };

  actionCount = (count) => {
    if (count <= 1) {
      return `Action (${count})`;
    } else {
      return `Actions (${count})`;
    }
  }

  navBar = (auth, showActions, selectedOption) => (
    <SubNav>
      <div className="breadcrumbs d-flex justify-content-between">
        {!showActions ? (
          <h2 className="title pr-2">
            Welcome to Home,
            <span className="text-capitalize">
              {' '}
              {auth.idToken && getFirstName(this.props.userdetails.fullname || auth.idToken.claims.name)} {' '}
            </span>
            <small className="d-block">
              Here's the overview of your Home today. Make the most of it.
            </small>
          </h2>
        ) : (
            <h2 className="title pr-2"> View Action {this.backButton()} </h2>
          )}
        <div className="d-flex justify-content-end align-items-center breadcrumbs-action ">
          {!showActions ? (
            <SearchForm
              onChange={this.handleChange}
              value={this.state.searchText}
              clear={this.clearSearch}
            />
          ) : (
              <ActionFilter
                onchange={this.onchangeFilter}
                value={selectedOption}
              />
            )}
          {!showActions ? (
            <ActionButton
              action={this.toggleAction}
              text={this.actionCount(this.props.actionCount)}
            />
          ) : (
              ''
            )}
        </div>
      </div>
    </SubNav>
  );

  card = isSearching =>
    (isSearching && this.props.homeCards.length > 0 ? (
      <CardNotFound text={NO_CARD} />
    ) : (
        <WelcomeSiteCard text={WELCOME_HOME_TEXT} />
      ));

  popUpHTML = (isShow, toggleSiteModel, isActionDetail, share_card_id, isAction) => (
    <FullViewCard
      isOpen={isShow}
      share_card_id={share_card_id}
      toggleModel={toggleSiteModel}
      isHome={!isActionDetail}
      handleDelete={this.handleDelete}
      isAction={isAction}
      handleBookmark={
        isActionDetail ? this.handleActionBookmark : this.handleBookmark
      }
    />
  );

  async updateCard(payload) {
    const { updateHomeCards, getHomeCards } = this.props;
    await updateHomeCards(payload, this.userId, this.clientCode);
    getHomeCards(this.userId, this.clientCode, false)
  }

  handleBookmarkArray = (bookmarkValue, id) => {
    let { bookmarkArray } = this.state;
    if (bookmarkValue) {
      bookmarkArray.push(id);
    } else if (bookmarkArray.includes(id) && !bookmarkValue) {
      const index = bookmarkArray.findIndex(element => element === id);
      delete bookmarkArray[index];
    }
  }

  render() {
    const {
      isOpen: { isShow, share_card_id, isAction },
      toggleSiteModel,
      loading: { loading }
    } = this.props;
    const {
      homeCards,
      sitedetails,
      showActions,
      actionList,
      currentPage,
      nextPage,
      selectedOption,
      isSearching,
      bookmarkArray
    } = this.state;
    const auth = JSON.parse(getItem(OKTA_TOKEN));
    if (!homeCards) {
      return (
        <>
          {this.navBar(auth)}
          <ScreenLoader />
        </>
      );
    }
    if (showActions) {
      return (
        <>
          {this.navBar(auth, showActions, selectedOption)}
          {this.popUpHTML(isShow, toggleSiteModel, true, share_card_id, isAction)}
          <Actions
            {...this.props}
            actions={actionList}
            handleBookmark={this.handleActionBookmark}
            loadAction={this.loadAction}
            currentPage={currentPage}
            setPage={this.setPage}
            nextPage={nextPage}
            selectedOption={selectedOption}
            handleNote={this.handleNote}
          />
        </>
      );
    }
    return (
      <>
        {this.navBar(auth)}
        {loading && (
          <div className="loader-full-page">
            <ScreenLoader />
          </div>
        )}
        {this.popUpHTML(isShow, toggleSiteModel, false, share_card_id, isAction)}
        {homeCards.length < 1 ? (
          this.card(isSearching)
        ) : (
            <div className="container">
              <SortableComponent
                items={homeCards}
                onSortEnd={this.onSortEnd}
                isShow={isShow}
                toggleSiteModel={toggleSiteModel}
                updateSize={this.updateCardSize}
                cardDetail={sitedetails}
                handleDelete={this.handleDelete}
                handleBookmark={this.handleBookmark}
                isHome
                handleNote={this.handleNote}
                bookmarkArray={bookmarkArray}
              />
            </div>
          )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  homeCards: state.homeCards,
  isOpen: state.toggleSiteModel,
  userId: state.userId,
  clientCode: state.clientInfo.clientCode,
  actions: state.Actions,
  actionCount: state.actionCount,
  hasNote: state.toggleAddNotesModel.hasNote,
  isAddNote: state.toggleAddNotesModel,
  userdetails: state.userDetails,
  loading: state.xhrStatus,
});

export default withAuth(
  connect(
    mapStateToProps,
    {
      getHomeCards,
      toggleSiteModel,
      updateMySites,
      getSite,
      toggleBookmark,
      updateHomeCards,
      getActions,
      contentDetail,
      getUnreadActionCount,
      deleteShareCard,
      setHomeCards,
      toggleAddNotesModel,
      actionDetail,
      toast
    }
  )(Home)
);
