import { SHOW_SHARE_MODAL, SHARE_USERS } from './actionType';
import { getShareUserApi, shareCardApi, deleteShareCardApi } from '../xhrCalls';
import {
  beginButtonLoader,
  endButtonLoader,
  beginCardLoader,
  endCardLoader
} from './xhrSatus';
import { toast } from './toast';
import { SHARED_TEXT, SHARED_DELETED } from '../../utils';

export const toggleShareModel = (
  isShareOpen,
  content_id,
  card_type,
  user,
  client
) => dispatch => {
  const params = {
    user_id: user,
    client: client,
    content_id: content_id,
    card_type: card_type
  };
  if (isShareOpen) {
    dispatch(beginButtonLoader());
    getShareUserApi(params)
      .then(response => {
        dispatch(endButtonLoader());
        dispatch({
          type: SHARE_USERS,
          payload: response.data.data
        });
        return response.data.data;
      })
      .catch(error => {
        dispatch(endButtonLoader());
        console.error(error);
        return error;
      });
  }
  dispatch({
    type: SHOW_SHARE_MODAL,
    payload: { isShareOpen, content_id, card_type }
  });
};

export const shareCard = (payload, userId, clientCode) => dispatch => {
  dispatch(beginButtonLoader());
  return shareCardApi(payload, userId, clientCode)
    .then(response => {
      dispatch(endButtonLoader());
      dispatch(toast(SHARED_TEXT, true));
      return response.data;
    })
    .catch(error => {
      dispatch(endButtonLoader());
      console.error(error);
      return error;
    });
};

export const deleteShareCard = (
  share_card_id,
  userId,
  clientCode
) => dispatch => {
  dispatch(beginCardLoader(share_card_id));
  deleteShareCardApi({ share_card_id, user_id: userId, client: clientCode })
    .then(response => {
      dispatch(endCardLoader());
      dispatch(toast(SHARED_DELETED, true));
      return response;
    })
    .catch(error => {
      dispatch(endCardLoader());
      console.error(error);
      return error;
    });
};
