import React from 'react';

export default function CardNotFound({ text }) {
  return (
    <div className="container">
      <div className="no-record-page no-data-found d-flex justify-content-center align-items-center flex-column h-50">
        <figure className="mb-0">
          <img
            className="no-page"
            src="/assets/images/no-data-found.svg"
            alt="No Data Found"
          />
        </figure>
        <h3>{text}</h3>
        <p>We can’t find any item matching your search.</p>
      </div>
    </div>
  );
}
