import React from 'react';
import ReactTooltip from 'react-tooltip';

import { Image } from '../layout/Image';
import CardActions from './CardActions';
import CardActionsFullView from './cardActionFullView';
import {
  SMALL,
  MYSITES,
  INTELLIGENCE,
  SITE,
  INTEL,
  APP,
  APPS,
  ACTION,
  ACTIONS,
  getCardType
} from '../../utils';

function handleActionScreen(props, type, size, isEnable) {
  const { card } = props;
  if (size === SMALL || card.url) {
    return (
      <CardActions
        className="action dropdown"
        {...props}
        type={type}
      />
    );
  } else if (size !== SMALL) {
    if (isEnable) {
      return (
        <CardActionsFullView
          className="action dropdown mobile-view"
          {...props}
          type={type}
        />
      );
    } else {
      return (
        <CardActions
          className="action dropdown mobile-view"
          {...props}
          type={type}
        />
      );
    }
  }
  return false;
}

const cardsType = card => {
  switch (card.card_type) {
    case SITE:
      return MYSITES;
    case INTEL:
      return INTELLIGENCE;
    case APP:
      return APPS;
    case ACTION:
      return ACTIONS;
    default:
      return false;
  }
};

export function CardMedia(props) {
  const { card, size, isEnable, from_user_email } = props;
  if (!card) {
    return false;
  }
  const type = cardsType(card);
  const cardType = getCardType(card);

  return (
    <div className="card-media">
      {(cardType === SITE && card.title) && (
        <div className="card-media-title">
          <h5>{card.title}</h5>
        </div>
      )}
      <Image
        className="card-img-top"
        src={(card && card.image) || '/assets/images/default-card.png'}
      />
      <div className="image-overlay" />
      {!!card.is_shared
      && <span className="shared-label" data-tip={`${card.from_user_email || from_user_email}`}>
        Shared by {card.from_user_email || from_user_email} <ReactTooltip />
      </span>}
      <div className="media-action">
        <span className={type ? `status-label ${type.toLowerCase()}` : ''}>
          {type}
        </span>
        {handleActionScreen(props, type, size, isEnable)}
      </div>
    </div>
  );
}
