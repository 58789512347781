import {
  getActionsApi,
  updateActionStatusApi,
  getUnreadActionCountApi
} from '../xhrCalls';
import { ACTIONS, ACTION_DETAIL, ACTIONS_COUNT } from './actionType';

/**
 * Action for get action list
 */
export const getActions = payload => dispatch =>
  getActionsApi(payload)
    .then(response => {
      const { data } = response;
      dispatch({
        type: ACTIONS,
        payload: data
      });
      return data;
    })
    .catch(error => {
      console.error(error);
      return error;
    });

/**
 * Action for toggle client
 */
export const AddAction = payload => dispatch => {
  dispatch({ type: ACTIONS, payload: payload });
};

/**
 * Action for action detail
 */
export const actionDetail = (payload, user) => dispatch => {
  const payloadParam = { ...payload };
  if (!payload.viewed && user.role_code !== 'ADMN') {
    const params = { user_id: payload.user_id, status: false, id: payload.id };
    updateActionStatusApi(params);
    payloadParam.viewed = true;
  }
  dispatch({ type: ACTION_DETAIL, payload: payloadParam });
};

/**
 * Action for update action status
 */
export const updateActionStatus = payload => () =>
  updateActionStatusApi(payload)
    .then(response => response)
    .catch(error => {
      console.error(error);
      return error;
    });

/**
 * Action for get action count
 */
export const getUnreadActionCount = payload => dispatch =>
  getUnreadActionCountApi(payload)
    .then(response => {
      const {
        data: { data }
      } = response;
      dispatch({
        type: ACTIONS_COUNT,
        payload: data[0].total_actions
      });
      return data;
    })
    .catch(error => {
      console.error(error);
      return error;
    });
