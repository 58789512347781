import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { toast } from '../../redux/actions';

class Toast extends PureComponent {
  closeAlert = (message, status) => {
    const { toast } = this.props;
    toast(message, status);
  };

  componentWillReceiveProps = nectProps => {
    const { toastStatus } = this.props;
    if (nectProps.toast.status !== toastStatus.status) {
      setTimeout(() => {
        this.closeAlert('', false);
      }, 10000);
    }
  };

  render() {
    const {
      toastStatus: { message, status, isError }
    } = this.props;
    return (
      <div className="container">
        <div
          className={`alert-custom alert-changepassword alert ${status &&
            'show'}`}
          role="alert"
        >
          <strong>
            <span className="alert-icon mr-3">
              <img
                width="24"
                height="24"
                src={
                  isError
                    ? '/assets/images/svgicons/error-icon.svg'
                    : '/assets/images/svgicons/sucess-icon.svg'
                }
                alt="Alert Icon"
              />
            </span>
            <span className="">{message}</span>
          </strong>
          <button
            type="button"
            className="close ml-3"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => this.closeAlert(message, false)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  toastStatus: state.toast
});

export default connect(
  mapStateToProps,
  { toast }
)(Toast);
