import React from "react";

import InformationTile from './InformationTile';

export default function CardInfo({ card }) {
  if (!card || (card.currentsqft === undefined)) {
    return false;
  }
  return (
    <div className="portfolio-product-info">
      <div className="row mr-0 ml-0 justify-content-between medium-card-media-row">
        <InformationTile title='Gross SQ FT' value={card.currentsqft} />
        <InformationTile title='Region' value={card.region} />
        <InformationTile title='Type' value={card.facilitypurposedesc} />
      </div>
    </div>
  );
}
