import { getContent } from '../xhrCalls';
import { SITE_DETAIL } from './actionType';
import { beginAjaxCall, endAjaxCall } from './xhrSatus';

/**
 * Action for get mysite detail
 */
export const contentDetail = (
  contentId,
  userId,
  clientId,
  isLoader,
  shared
) => dispatch => {
  isLoader && dispatch(beginAjaxCall());
  const isShared = shared ? 1 : 0;
  return getContent(contentId, userId, clientId, isShared)
    .then(response => {
      isLoader && dispatch(endAjaxCall());
      dispatch({
        type: SITE_DETAIL,
        payload: response.data.data
      });
      return response.data.data;
    })
    .catch(error => {
      isLoader && dispatch(endAjaxCall());
      console.error(error);
      return error;
    });
};
