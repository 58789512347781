const initialState = { loading: false };
export const xhrStatus = (state, action) => {
  const { type, loading } = action;
  if (typeof state === 'undefined') {
    return initialState;
  } else if (type === 'BEGIN_AJAX_CALL') {
    return { ...state, loading: loading };
  } else if (type === 'END_AJAX_CALL') {
    return { ...state, loading: loading };
  } else {
    return state;
  }
};

/**
 * function to handle button loader
 */
const initialValue = { buttonLoader: false };
export const buttonAjaxStatus = (state, action) => {
  const { type, buttonLoader } = action;
  if (typeof state === 'undefined') {
    return initialValue;
  } else if (type === 'BEGIN_BUTTON_LOADER') {
    return { ...state, buttonLoader: buttonLoader };
  } else if (type === 'END_BUTTON_LOADER') {
    return { ...state, buttonLoader: buttonLoader };
  } else {
    return state;
  }
};

/**
 * function to handle card loader
 */
const initialCardValue = { cardLoader: false };
export const cardAjaxStatus = (state, action) => {
  const { type, cardLoader } = action;
  if (typeof state === 'undefined') {
    return initialCardValue;
  } else if (type === 'BEGIN_CARD_LOADER') {
    return { ...state, cardLoader: cardLoader };
  } else if (type === 'END_CARD_LOADER') {
    return { ...state, cardLoader: cardLoader };
  } else {
    return state;
  }
};
