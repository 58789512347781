export const USER_DETAIL = 'USER_DETAIL';
export const MYSITES = 'MYSITES';
export const SHOW_SITE_MODAL = 'SHOW_SITE_MODAL';
export const MY_SITE = 'MY_SITE';
export const TERMS = 'TERMS';
export const SAVE_MYSITES_STATE = 'SAVE_MYSITES_STATE';
export const ENABLE_SORTING = 'ENABLE_SORTING';
export const INTELLIGENCE = 'INTELLIGENCE';
export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const END_AJAX_CALL = 'END_AJAX_CALL';
export const HOME = 'HOME';
export const SITE_DETAIL = 'SITE_DETAIL';
export const USER_CLIENT = 'USER_CLIENT';
export const USER_ID = 'USER_ID';
export const CLIENT_CODE = 'CLIENT_CODE';
export const ACTIONS = 'ACTIONS';
export const ADD_ACTION = 'ADD_ACTION';
export const ACTION_DETAIL = 'ACTION_DETAIL';
export const APPLICATIONS = 'APPLICATIONS';
export const SHOW_SHARE_MODAL = 'SHOW_SHARE_MODAL';
export const SHOW_ADD_NOTES_MODAL = 'SHOW_ADD_NOTES_MODAL';
export const ACTIONS_COUNT = 'ACTIONS_COUNT';
export const NOTES = 'NOTES';
export const SHARE_USERS = 'SHARE_USERS';
export const TOAST = 'TOAST';
export const BEGIN_BUTTON_LOADER = 'BEGIN_BUTTON_LOADER';
export const END_BUTTON_LOADER = 'END_BUTTON_LOADER';
export const NOTES_INFO = 'NOTES_INFO';
export const LOAD_ACTION = 'LOAD_ACTION';
export const PROFILE_PIC = 'PROFILE_PIC';
export const BEGIN_CARD_LOADER = 'BEGIN_CARD_LOADER';
export const END_CARD_LOADER = 'END_CARD_LOADER';
