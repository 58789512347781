import React from 'react';

import { connect } from 'react-redux';

import CardActions from '../cards/cardActionFullView';
import { convertDate } from '../../utils';

const field = (label, description, className) => (
  <div className={className}>
    <h6 className="static-label">{label}</h6>
    <p className="static-label-data">{description}</p>
  </div>
);

function Actiondetail(props) {
  const { detail, handleBookmark, isHome, share_card_id, handleDelete } = props;
  return (
    <div className="container">
      <div className="row action-details-view mt-70">
        <div className="col-sm-12 d-flex justify-content-between title-wrap">
          <div className="title-action">
            <h5>
              <span>{detail.title}</span>
              <span className="status-label">Actions</span>
            </h5>
            <p className="address">{detail.client}</p>
          </div>
          <span className="media-action justify-content-end">
            <CardActions
              className="action dropdown desktop-view mobile-view d-block"
              isActionDetail
              share_card_id={share_card_id}
              card={detail}
              isHome={isHome}
              handleDelete={handleDelete}
              handleBookmark={handleBookmark}
            />
          </span>
        </div>
        {field('Descrition', detail.text, 'col-sm-12 mb-32 mt-32')}
        <div className="col-sm-6 mb-32">
          <h6 className="static-label">Link</h6>
          <p className="static-label-data">
            <a href={`${detail.url}`} target="__blank" className="link">
              {detail.url}
            </a>
          </p>
        </div>
        {field('Source', 'Insite Announcement', 'col-sm-6 mb-32')}
        <div className="col-sm-6 mb-32">
          <h6 className="static-label">Severity</h6>
          <p className="static-label-data">
            <span className="icon">
              <img
                width="24"
                className="priority-icon"
                src={`assets/images/svgicons/${detail.priority &&
                  detail.priority.toLowerCase()}-priority.svg`}
                alt="Priority Icon"
              />
            </span>
            <span className="text-icon">{detail.priority}</span>
          </p>
        </div>
        {field(
          'Action created on',
          convertDate(detail.sentdatetime),
          'col-sm-6 mb-32'
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  detail: state.actionDetail
});

export default connect(
  mapStateToProps,
  {}
)(Actiondetail);
