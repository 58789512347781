import axios from 'axios';
import { WRITE_NOTE, DELETE_NOTE, GET_NOTE } from '../../constants';

export const getNoteApi = (payload) =>
  axios.get(GET_NOTE, { params: payload });

export const writeNoteApi = (payload, userId) =>
  axios.post(`${WRITE_NOTE}?user_id=${userId}`, payload);

export const deleteNoteApi = (payload) =>
  axios.get(DELETE_NOTE, { params: payload });

