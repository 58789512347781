import React from 'react';
import { Link } from 'react-router-dom';

function navLink(tag, to) {
  const className = window.location.href.includes(to) ? 'active' : '';
  return (
    <Link className={`nav-link ${className}`} to={to}>
      <span className={`icon-${to.slice(1)} mobile-view`}>{tag}</span>
      <span className="desktop-view">{tag}</span>
    </Link>
  );
}

export default function NavItems({ wrapperClass, itemClass }) {
  return (
    <ul className={wrapperClass}>
      <li className={itemClass}>{navLink('Home', '/home')}</li>
      <li className={itemClass}>{navLink('Intelligence', '/intelligence')}</li>
      <li className={itemClass}>{navLink('MySites', '/mysites')}</li>
      <li className={itemClass}>{navLink('Applications', '/applications')}</li>
    </ul>
  );
}
