import { SHOW_ADD_NOTES_MODAL, NOTES, NOTES_INFO } from '../actions';

/**
 * Function for toggle add note modal
 * @param  {Object} state
 * @param  {Object} action
 */
const initialValue = {};
export const toggleAddNotesModel = (state, action) => {
  const { type, payload } = action;
  if (typeof state === 'undefined') {
    return initialValue;
  } else if (type === SHOW_ADD_NOTES_MODAL) {
    return { ...state, ...payload };
  } else {
    return state;
  }
};

const initialState = '';
export const notes = (state, action) => {
  const { type, payload } = action;
  if (typeof state === 'undefined') {
    return initialState;
  } else if (type === NOTES) {
    return payload;
  } else {
    return state;
  }
};

const initial = {};
export const notes_info = (state, action) => {
  const { type, payload } = action;
  if (typeof state === 'undefined') {
    return initial;
  } else if (type === NOTES_INFO) {
    return { ...initial, ...payload };
  } else {
    return state;
  }
};
