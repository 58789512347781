import { ENABLE_SORTING } from '../actions';

const initialState = {};
export const enableSorting = (state, action) => {
  const { type, payload } = action;
  if (typeof state === 'undefined') {
    return initialState;
  } else if (type === ENABLE_SORTING) {
    return { ...state, ...payload };
  } else {
    return state;
  }
};
