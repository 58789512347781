import React, { useState } from 'react';

import FloatingLabelInput from 'react-floating-label-input';

export default function FloatingLabel({
  id,
  label,
  value,
  readOnly,
  handleOnChange,
  defaultValue,
  className,
  error,
  type
}) {
  const [focus, setFocus] = useState();
  return (
    <div
      className={`${className} mb-42 ${readOnly &&
        'form-readonly'} ${!readOnly && focus && 'focus'}`}
    >
      <FloatingLabelInput
        id={id}
        label={label}
        defaultValue={defaultValue}
        type={type}
        readOnly={readOnly}
        onChange={event => handleOnChange(event)}
        onFocus={() => setFocus(id)}
        onBlur={() => setFocus()}
        value={value}
      />
      <div className="is-invalid">{error}</div>
    </div>
  );
}
