import {
  startSessionApi,
  getUserDetailApi,
  updateUserDetailApi,
  uploadProfilePicApi
} from '../xhrCalls';
import { USER_DETAIL, USER_ID, CLIENT_CODE, PROFILE_PIC } from './actionType';
import { toast } from './toast';
import { beginButtonLoader, endButtonLoader } from './xhrSatus';
import { MY_PROFILE_TEXT, MY_PROFILE_PIC_TEXT, MY_PROFILE_PIC_TEXT_REMOVED, IMAGE_ERROR } from '../../utils';

/**
 * Action to start session
 * @param  {Object} payload
 */
export const startSession = payload => dispatch =>
  startSessionApi(payload)
    .then(response => {
      const userid = response.data.data[0].id;
      dispatch({
        type: USER_ID,
        payload: userid
      });
      sessionStorage.setItem('userId', userid);
      return userid;
    })
    .catch(error => {
      console.error(error);
    });

/**
 * Action for get user detail
 */
export const getUserDetail = userId => dispatch =>
  getUserDetailApi(userId)
    .then(response => {
      const { clientcode, company, logofilename } = response.data.data[0];
      const clientCode = sessionStorage.getItem('clientCode') || clientcode;
      const clientName = sessionStorage.getItem('clientName') || company;
      const clientImage = sessionStorage.getItem('clientImage') || logofilename;
      sessionStorage.setItem('clientCode', clientCode);
      sessionStorage.setItem('clientName', clientName);
      sessionStorage.setItem('clientImage', clientImage);
      dispatch({
        type: USER_DETAIL,
        payload: response.data.data[0]
      });
      dispatch({
        type: CLIENT_CODE,
        payload: {
          clientCode: clientCode,
          clientLogo: clientImage,
          clientName: clientName
        }
      });
    })
    .catch(error => console.error(error));

/**
 * Action for update user detail
 */
export const updateUserDetail = (userId, payload) => dispatch => {
  dispatch(beginButtonLoader());
  return updateUserDetailApi(userId, payload)
    .then(response => {
      dispatch(endButtonLoader());
      dispatch(toast(MY_PROFILE_TEXT, true));
      dispatch({
        type: USER_DETAIL,
        payload: response.data.data[0]
      });
    })
    .catch(error => {
      dispatch(endButtonLoader());
      console.error(error);
    });
};


/**
 * Action for update user profile
 */
export const uploadProfilePic = (userId, payload) => dispatch => uploadProfilePicApi(userId, payload)
    .then(response => {
      dispatch({
        type: PROFILE_PIC,
        payload: response.data.data
      });
      getUserDetail(userId);
      dispatch(toast(MY_PROFILE_PIC_TEXT, true));
      return response.data.data;
    })
    .catch(error => {
      dispatch(toast(IMAGE_ERROR, true, true));
      console.error(error);
    });

export const removedProfilePic = (userId, payload) => dispatch => uploadProfilePicApi(userId, payload)
    .then(response => {
      dispatch({
        type: PROFILE_PIC,
        payload: response.data.data
      });
      getUserDetail(userId);
      dispatch(toast(MY_PROFILE_PIC_TEXT_REMOVED, true));
      return response.data.data;
    })
    .catch(error => {
      dispatch(toast(IMAGE_ERROR, true, true));
      console.error(error);
    });
