import { getHomeCardsApi, updateHomeApi } from '../xhrCalls';
import { beginAjaxCall, endAjaxCall } from './xhrSatus';
import { HOME } from './actionType';

/**
 * Action for get home list
 */
export const getHomeCards = (userId, client, loader) => dispatch => {
  loader && dispatch(beginAjaxCall())
  return getHomeCardsApi(userId, client)
    .then(response => {
      dispatch(endAjaxCall())
      dispatch({
        type: HOME,
        payload: response.data.data
      });
      return response;
    })
    .catch(error => {
      dispatch(endAjaxCall())
      console.error(error);
    });
}

export const setHomeCards = cards => dispatch =>
  dispatch({
    type: HOME,
    payload: cards
  });

/**
 * Action for updating home list
 */
export const updateHomeCards = (payload, userId, clientId) => () =>
  updateHomeApi(payload, userId, clientId)
    .then(response => response)
    .catch(error => {
      console.error(error);
      return error;
    });
