import { combineReducers } from 'redux';

import { xhrStatus, buttonAjaxStatus, cardAjaxStatus } from './xhrStatus';
import { userDetails, profilePic } from './userdetail';
import { terms } from './terms';
import { mySites } from './mySites';
import { toggleSiteModel } from './toggleSiteModel';
import { mySite } from './mySite';
import { enableSorting } from './enableSoring';
import { intelligences } from './intelligence';
import { homeCards } from './home';
import { siteDetail } from './siteDetail';
import { client, clientInfo } from './client';
import { userId } from './setUserId';
import { Actions, actionDetail, actionCount } from './actions';
import { Apps } from './applications';
import { toggleShareModel, shareUsers } from './toggleShareModel';
import { toggleAddNotesModel, notes, notes_info } from './addNotes';
import { toast } from './toast';

const appReducers = combineReducers({
  xhrStatus,
  userDetails,
  mySites,
  toggleSiteModel,
  terms,
  mySite,
  enableSorting,
  intelligences,
  siteDetail,
  client,
  userId,
  clientInfo,
  Actions,
  actionDetail,
  Apps,
  toggleShareModel,
  toggleAddNotesModel,
  actionCount,
  homeCards,
  notes,
  shareUsers,
  toast,
  buttonAjaxStatus,
  notes_info,
  profilePic,
  cardAjaxStatus
});

const rootReducer = (state, action) => appReducers(state, action);

export default rootReducer;
