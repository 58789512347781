import { SHOW_ADD_NOTES_MODAL, NOTES, NOTES_INFO } from "./actionType";
import { writeNoteApi, deleteNoteApi, getNoteApi } from "../xhrCalls";
import { toast } from "./toast";
import { beginButtonLoader, endButtonLoader } from "./xhrSatus";
import { ADD_NOTE_TEXT, DELETED_TEXT } from "../../utils";

export const toggleAddNotesModel = (
  isAddNoteOpen,
  content_id,
  type,
  hasNote,
  user,
  client
) => dispatch => {
  const params = {
    user_id: user,
    client: client,
    content_id: content_id,
    card_type: type
  };
  if (isAddNoteOpen) {
    hasNote && dispatch(beginButtonLoader());
    getNoteApi(params)
      .then(response => {
        hasNote && dispatch(endButtonLoader());
        dispatch({
          type: NOTES,
          payload: hasNote ? response.data.data[0].note : undefined
        });
        return response;
      })
      .catch(error => {
        hasNote && dispatch(endButtonLoader());
        console.error(error);
        return error;
      });
  }
  dispatch({
    type: SHOW_ADD_NOTES_MODAL,
    payload: { isAddNoteOpen, content_id, type, hasNote }
  });
  !isAddNoteOpen && dispatch({ type: NOTES, payload: "" });
};

/**
 * Action for adding note
 */
export const writeNote = (payload, userId) => dispatch => {
  dispatch(beginButtonLoader());
  return writeNoteApi(payload, userId)
    .then(response => {
      dispatch(endButtonLoader());
      dispatch(toast(ADD_NOTE_TEXT, true));
      return response;
    })
    .catch(error => {
      dispatch(endButtonLoader());
      console.error(error);
      return error;
    });
};

/**
 * Action for deleting note
 */
export const deleteNote = payload => dispatch =>
  deleteNoteApi(payload)
    .then(response => {
      dispatch(toast(DELETED_TEXT, true));
      return response;
    })
    .catch(error => {
      console.error(error);
      return error;
    });

/**
 * Action for getting note
 */
export const getNote = (payload, key) => dispatch =>
  getNoteApi(payload)
    .then(response => {
      response.data.data[0].key = key;
      dispatch({
        type: NOTES_INFO,
        payload: response.data.data[0] || key
      });
      return response.data.data[0].note_id;
    })
    .catch(error => {
      dispatch({
        type: NOTES_INFO,
        payload: { key }
      });
      console.error(error);
      return error;
    });
