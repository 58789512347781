const config = {
  local: {
    api_url: process.env.REACT_APP_API_END_POINT_DEV,
    OKTA_BASE_URL: process.env.REACT_APP_OKTA_BASE_URL,
    OKTA_CLIENT_ID: process.env.REACT_APP_OKTA_LOCAL_CLIENT_ID,
    OKTA_API_KEY: process.env.REACT_APP_OKTA_API_KEY
  },
  development: {
    api_url: process.env.REACT_APP_API_END_POINT_DEV,
    OKTA_BASE_URL: process.env.REACT_APP_OKTA_BASE_URL,
    OKTA_CLIENT_ID: process.env.REACT_APP_OKTA_DEV_CLIENT_ID,
    OKTA_API_KEY: process.env.REACT_APP_OKTA_API_KEY
  },
  qa: {
    api_url: process.env.REACT_APP_API_END_POINT_QA,
    OKTA_BASE_URL: process.env.REACT_APP_OKTA_BASE_URL,
    OKTA_CLIENT_ID: process.env.REACT_APP_OKTA_QA_CLIENT_ID,
    OKTA_API_KEY: process.env.REACT_APP_OKTA_API_KEY
  },
  staging: {
    api_url: process.env.REACT_APP_API_END_POINT_STAGE,
    OKTA_BASE_URL: process.env.REACT_APP_OKTA_BASE_URL,
    OKTA_CLIENT_ID: process.env.REACT_APP_OKTA_STAGING_CLIENT_ID,
    OKTA_API_KEY: process.env.REACT_APP_OKTA_API_KEY
  },
  production: {
    api_url: process.env.REACT_APP_API_END_POINT_PROD,
    OKTA_BASE_URL: process.env.REACT_APP_OKTA_BASE_URL,
    OKTA_CLIENT_ID: process.env.REACT_APP_OKTA_PROD_CLIENT_ID,
    OKTA_API_KEY: process.env.REACT_APP_OKTA_API_KEY
  }
}[process.env.REACT_APP_ENV || 'development'];
export default config;
