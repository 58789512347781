import React, { useState } from 'react';
import { connect } from 'react-redux';

import { ModalView } from '../layout/ModalView';
import FloatingLabelInput from './FloatingLabel';
import { UpdatePassword } from '../../redux/actions';
import { REQUIRED, INVALID_PASSWORD, ValidatePassword } from '../../utils';

const Footer = ({ handleClick, error }) => (
  <div className="action-btn d-flex justify-content-between align-items-center w-100">
    <div className="is-invalid">{error}</div>
    <button
      type="button"
      className="btn primary modal-primary-btn"
      onClick={() => handleClick()}
    >
      Update Password
    </button>
  </div>
);

const ChangePassword = props => {
  const initialState = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  };
  const [fields, setFields] = useState(initialState);
  const { isOpen, toggleModel, UpdatePassword } = props;

  const handleToggle = () => {
    setFields(initialState);
    toggleModel('showChangePassword', false);
  };

  const handleChangePassword = async () => {
    const state = { ...fields };
    delete state.currentPasswordError;
    delete state.newPasswordError;
    delete state.confirmPasswordError;
    if (!fields.currentPassword) {
      state.currentPasswordError = REQUIRED;
    }
    if (!fields.newPassword) {
      state.newPasswordError = REQUIRED;
    }
    if (!fields.confirmPassword) {
      state.confirmPasswordError = REQUIRED;
    }
    if (
      !ValidatePassword(fields.confirmPassword) &&
      fields.confirmPassword.length > 0
    ) {
      state.newPasswordError = INVALID_PASSWORD;
    }
    if (
      !ValidatePassword(fields.currentPassword) &&
      fields.currentPassword.length > 0
    ) {
      state.currentPasswordError = INVALID_PASSWORD;
    }
    setFields(state);
    const currentPassword = fields.currentPassword.trim();
    const newPassword = fields.newPassword.trim();
    const confirmPassword = fields.confirmPassword.trim();
    if (currentPassword && newPassword && confirmPassword) {
      if (newPassword === confirmPassword) {
        const payload = {
          oldPassword: currentPassword,
          newPassword: newPassword
        };
        await UpdatePassword(payload).then(res => {
          if (!res.message) {
            handleToggle();
            setFields(initialState);
          }
        });
      } else {
        state.confirmPasswordError = 'New and old password does not match.';
        setFields(state);
      }
    }
  };

  function onChange(event) {
    const { id, value, key } = event.target;
    if (key === 'Enter') {
      handleChangePassword();
      return;
    }
    const state = { ...fields };
    state[id] = value;
    setFields(state);
  }
  return (
    <ModalView
      modelClass="modal-dialog-centered modal-md-custom"
      toggle={() => handleToggle()}
      bodyClass="modal-md-custom-body"
      isOpen={isOpen}
      heading="Change Password"
      isShare
      footer={
        <Footer handleClick={handleChangePassword} error={fields.error} />
      }
    >
      <div className="form-group mb-5 select-box--custom floating-select-box custom-form">
        <div
          className={`row ${fields.addFocus === 'currentPassword' && 'focus'}`}
        >
          <FloatingLabelInput
            id="currentPassword"
            label="Current Password"
            name="currentPassword"
            className="col-sm-12"
            type="password"
            handleOnChange={onChange}
            value={fields.currentPassword}
            error={fields.currentPasswordError}
          />
        </div>
        <div className={`row ${fields.addFocus === 'newPassword' && 'focus'}`}>
          <FloatingLabelInput
            id="newPassword"
            label="New Password"
            name="newPassword"
            className="col-sm-12"
            type="password"
            handleOnChange={onChange}
            value={fields.newPassword}
            error={fields.newPasswordError}
          />
        </div>
        <div
          className={`row ${fields.addFocus === 'confirmPassword' && 'focus'}`}
        >
          <FloatingLabelInput
            id="confirmPassword"
            label="Confirm Password"
            name="confirmPassword"
            className="col-sm-12"
            type="password"
            handleOnChange={onChange}
            value={fields.confirmPassword}
            error={fields.confirmPasswordError}
          />
        </div>
      </div>
    </ModalView>
  );
};
const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  { UpdatePassword }
)(ChangePassword);
