import { applyMiddleware, createStore, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'

import rootReducer from '../reducers'
import monitorReducerEnhancer from './monitorReducerEnhancer'

const initial_state = window.__REDUX_STATE__ ? window.__REDUX_STATE__ : undefined;
const middlewareEnhancer = applyMiddleware(thunkMiddleware);
const composedEnhancers = compose(
  middlewareEnhancer,
  monitorReducerEnhancer
);

const store = createStore(rootReducer, initial_state, composedEnhancers);

export default store;
