import { getAppsApi, updateAppsApi } from "../xhrCalls";
import { APPLICATIONS } from "./actionType";
import { beginAjaxCall, endAjaxCall } from './xhrSatus'

/**
 * Action for get intelligence list
 */
export const getApps = (userId, clientId, loading) => dispatch => {
  loading && dispatch(beginAjaxCall())
  return getAppsApi(userId, clientId)
    .then(response => {
      dispatch(endAjaxCall())
      dispatch({
        type: APPLICATIONS,
        payload: response.data.data
      });
      return response;
    })
    .catch(error => {
      dispatch(endAjaxCall())
      console.error(error);
    });
}

/**
 * Action for updating intelligence list
 */
export const updateApp = (payload, userId, clientId) => () =>
  updateAppsApi(payload, userId, clientId)
    .then(response => response)
    .catch(error => {
      console.error(error);
      return error;
    });

export const setAppsCards = cards => dispatch =>
  dispatch({
    type: APPLICATIONS,
    payload: cards
  });
