import React from 'react';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import FloatingLabel from 'floating-label-react';
import 'floating-label-react/styles.css';

const animatedComponents = makeAnimated();
const styles = {
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? '#fff' : '',
    textColor: state.isSelected ? '#000' : ''
  })
};
const multiSelectStyle = {
  container: provided => ({
    ...provided,
    marginTop: 50
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: 'absolute',
    top: state.hasValue || state.selectProps.inputValue ? -15 : '50%',
    transition: 'top 0.1s, font-size 0.1s',
    fontSize: (state.hasValue || state.selectProps.inputValue) && 13
  })
};
export const SelectField = ({
  options,
  placeholder,
  onChange,
  value,
  name
}) => (
    <Select
      options={options}
      isMulti
      value={value}
      name={name}
      className="basic-single normal"
      classNamePrefix="select"
      isSearchable
      isClearable={false}
      placeholder={placeholder}
      onChange={onChange}
      components={animatedComponents}
      styles={styles}
    />
  );
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => (
  <ValueContainer {...props}>
    <Placeholder {...props} isFocused={props.isFocused}>
      {props.selectProps.placeholder}
    </Placeholder>
    {React.Children.map(children, child =>
      (child && child.type !== Placeholder ? child : null))}
  </ValueContainer>
);

export const MultiSelect = ({
  options,
  placeholder,
  onChange,
  value,
  onFocus,
  error
}) => (
    <>
      <Select
        options={options}
        isMulti
        value={value}
        components={{
          ValueContainer: CustomValueContainer,
          DropdownIndicator: () => null
        }}
        className="basic-multi-select multi"
        classNamePrefix="select"
        closeMenuOnSelect
        onChange={event => onChange(event)}
        placeholder={placeholder}
        styles={multiSelectStyle}
        isSearchable
        isClearable={false}
        onMenuOpen={() => onFocus(true)}
        onMenuClose={() => onFocus(false)}
        noOptionsMessage={() => 'No User(s) available'}
      />
      <div className="is-invalid">{error}</div>
    </>
  );
export const TextField = ({
  name,
  label,
  wrapperClass,
  component,
  value,
  onChange,
  onFocus,
  readOnly
}) => (
    <div className={wrapperClass}>
      <FloatingLabel
        name={name}
        readOnly={readOnly}
        placeholder={label}
        component={component}
        value={value}
        onChange={onChange}
        onFocus={() => onFocus(name)}
        onBlur={() => onFocus('')}
      />
    </div>
  );
