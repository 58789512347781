import React from 'react';
import Button from 'reactstrap/lib/Button';
import Pagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import 'rc-pagination/assets/index.css';
import AddNotes from '../layout/AddNotes';

import CardActions from '../cards/CardActions';
import {
  truncateString,
  convertDate,
  WELCOME_ACTIONS_TEXT,
  CARDS_PER_PAGE
} from '../../utils';
import WelcomeSiteCard from '../cards/WelcomeCard';

const cardFooter = action => (
  <div className="card-footer d-flex justify-content-between">
    <span className="date">{convertDate(action.sentdatetime)}</span>
    <Button className="d-none">Mark as completed</Button>
  </div>
);

const cardBody = action => (
  <div className="card-body">
    <h5 className="card-title d-flex align-items-start">
      <img
        width="24"
        className="priority-icon"
        src={`assets/images/svgicons/${action.priority.toLowerCase()}-priority.svg`}
        alt="Priority Icon"
      />
      {truncateString(action.title, 24)}
    </h5>
    <p className="card-text mt-1">{action.client && truncateString(action.client, 26)}</p>
    <p className="card-description">{truncateString(action.text, 100)}</p>
  </div>
);

const cardHead = props => (
  <div className="card-media">
    <div className="media-action justify-content-end">
      <CardActions
        className="action dropdown desktop-view mobile-view d-block"
        {...props}
        card={props.action}
        isActionDetail
        isEnable
      />
    </div>
  </div>
);

const arrowPath =
  'M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h' +
  '-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v' +
  '60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91' +
  '.5c1.9 0 3.8-0.7 5.2-2L869 536.2c14.7-12.8 14.7-35.6 0-48.4z';

const doublePath = [
  'M533.2 492.3L277.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H188c-6' +
  '.7 0-10.4 7.7-6.3 12.9L447.1 512 181.7 851.1c-4.1 5.2-0' +
  '.4 12.9 6.3 12.9h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.' +
  '1c9.1-11.7 9.1-27.9 0-39.5z',
  'M837.2 492.3L581.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H492c-6' +
  '.7 0-10.4 7.7-6.3 12.9L751.1 512 485.7 851.1c-4.1 5.2-0' +
  '.4 12.9 6.3 12.9h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.' +
  '1c9.1-11.7 9.1-27.9 0-39.5z'
];

const getSvgIcon = (path, reverse, type) => {
  const paths = Array.isArray(path) ? path : [path];
  const renderPaths = paths.map((p, i) => <path key={i} d={p} />);
  return (
    <i
      className={`custom-icon-${type}`}
      style={{
        fontSize: '13px'
      }}
    >
      <svg
        viewBox="0 0 1024 1024"
        width="14px"
        height="14px"
        fill="#137ec0"
        style={{
          verticalAlign: '-.125em',
          transform: `rotate(${(reverse && 180) || 0}deg)`
        }}
      >
        {renderPaths}
      </svg>
    </i>
  );
};

export const ActionCard = props => (
  <div className={props.action.viewed ? 'card read' : 'card'}>
    {cardHead(props)}
    {cardBody(props.action)}
    {cardFooter(props.action)}
  </div>
);

const nextIcon = getSvgIcon(arrowPath, false, 'next');
const prevIcon = getSvgIcon(arrowPath, true, 'prev');
const jumpNextIcon = () => getSvgIcon(doublePath, false, 'jump-next');
const jumpPrevIcon = () => getSvgIcon(doublePath, true, 'jump-prev');

const ActionsList = props => {
  const iconsProps = {
    prevIcon,
    nextIcon,
    jumpPrevIcon,
    jumpNextIcon
  };
  const {
    actions,
    loadAction,
    actionCount,
    selectedOption,
    currentPage,
    setPage
  } = props;
  const itemRender = (current, type, element) => {
    if (type === 'page') {
      return (
        <a className={`page-link ${currentPage === current && ''}`} href>
          {current}
        </a>
      );
    }
    return element;
  };

  const onChangePage = page => {
    setPage(page);
    loadAction(page);
  };
  return (
    <div className="grid-view action-card">
      <ul className="row">
        {actions.map(action => (
          <li className="card-view small-card" key={Math.random()} onDoubleClick={() => props.handleDoubleClick(action)}>
            <ActionCard {...props} action={action} currentPage={currentPage} />
          </li>
        ))}
      </ul>
      {(selectedOption && selectedOption.value !== 'All') ||
        actionCount <= CARDS_PER_PAGE ? (
          ''
        ) : (
          <nav className="pagination-nav">
            <Pagination
              onChange={page => onChangePage(page)}
              current={currentPage}
              pageSize={CARDS_PER_PAGE}
              total={actionCount}
              className="pagination"
              itemRender={itemRender}
              showLessItems
              style={{ marginBottom: '2rem' }}
              {...iconsProps}
              locale={localeInfo}
            />
          </nav>
        )}
    </div>
  );
};
export default function Actions(props) {
  const { isOpen, actions, actionDetail, loadAction, currentPage, userdetails, toggleSiteModel } = props;

  async function handleDoubleClick(action) {
    await actionDetail(action, userdetails);
    loadAction && loadAction(currentPage);
    toggleSiteModel(!isOpen.isShow, action.share_card_id, true);
  }
  return (
    <div className="container">
      {props.isAddNote.isAddNoteOpen && (
        <AddNotes
          isOpen={props.isAddNote.isAddNoteOpen}
          toggleModel={props.toggleAddNotesModel}
          {...props}
        />
      )}
      {actions.length > 0 ? (
        <ActionsList {...props} handleDoubleClick={handleDoubleClick} />
      ) : (
          <WelcomeSiteCard text={WELCOME_ACTIONS_TEXT} />
        )}
    </div>
  );
}
