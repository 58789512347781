import { getModules, toggleModule } from '../xhrCalls';

/**
 * Action for get module list
 */
export const getUserModules = userId => () =>
  getModules(userId)
    .then(response => response)
    .catch(error => {
      console.error(error);
      return error;
    });

/**
 * Action for toggle module status
 */
export const toggleModuleStatus = payload => () =>
  toggleModule(payload)
    .then(response => response)
    .catch(error => {
      console.error(error);
      return error;
    });
