import React, { useEffect, useState } from 'react';
import { withAuth } from '@okta/okta-react';
import { connect } from 'react-redux';

function Footer(props) {
  const [authenticated, setAuth] = useState(null);
  async function checkAuthentication() {
    const authenticate = await props.auth.isAuthenticated();
    if (authenticate !== authenticated) {
      setAuth({ authenticated: authenticate });
    }
  }

  useEffect(() => {
    checkAuthentication();
  });

  if (!authenticated) {
    return false;
  }

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content d-flex justify-content-between align-items-center">
          <p className="copyright">
            Copyright © {new Date().getFullYear()} InSite. All rights reserved.
          </p>
          <p className="toc">
            <a className="toc-link" href={props.terms} target="__blank">
              Terms of use
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
const mapStateToProps = state => ({
  terms: state.terms
});

export default withAuth(
  connect(
    mapStateToProps,
    {}
  )(Footer)
);
