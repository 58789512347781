import { ACTIONS, ACTION_DETAIL, ACTIONS_COUNT } from '../actions/actionType';

/**
 * Function for user client
 * @param  {Object} state
 * @param  {Object} action
 */ const initialState = {};
export const Actions = (state, action) => {
  const { type, payload } = action;
  if (typeof state === 'undefined') {
    return initialState;
  } else if (type === ACTIONS) {
    return { ...initialState, ...payload };
  } else {
    return state;
  }
};


/**
 * Function for action detail
 * @param  {Object} state
 * @param  {Object} action
 */ const initialValue = {};
export const actionDetail = (state, action) => {
  const { type, payload } = action;
  if (typeof state === 'undefined') {
    return initialValue;
  } else if (type === ACTION_DETAIL) {
    return payload;
  } else {
    return state;
  }
};

/**
 * Function for action count
 * @param  {Object} state
 * @param  {Object} action
 */ const initial = 0;
export const actionCount = (state, action) => {
  const { type, payload } = action;
  if (typeof state === 'undefined') {
    return initial;
  } else if (type === ACTIONS_COUNT) {
    return payload;
  } else {
    return state;
  }
};
