import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';

import {
  toggleSiteModel,
  updateMySites,
  getIntelligence,
  contentDetail,
  toggleBookmark,
  updateIntelligence,
  setIntelCards,
  toast
} from '../../../redux/actions';

import { updateIntelApi } from '../../../redux/xhrCalls';
import {
  updateSizePayload,
  updateOrderPayload,
  SMALL,
  WELCOME_INTELLIGENCE_TEXT,
  INTELLIGENCE,
  searchInArray,
  NO_CARD,
  UNBOOKMARKED_TEXT,
  BOOKMARKED_TEXT,
  insertKeyValue
} from '../../../utils';
import FullViewCard from '../../cards/FullViewCard';
import WelcomeSiteCard from '../../cards/WelcomeCard';
import CardNotFound from '../../cards/CardNotFound';
import { ScreenLoader } from '../../layout/ScreenLoader';
import SortableComponent from '../../cards/Sortable';
import SubNav, { SearchForm } from '../../layout/SubNav';

class Intelligence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sitedetails: { searchText: '' },
      bookmarkArray: []
    };
    this.userId = sessionStorage.getItem('userId');
    this.clientCode = sessionStorage.getItem('clientCode');
  }

  componentDidMount() {
    const { clientCode, userId } = this.props;
    this.userId = userId || this.userId;
    this.clientCode = this.clientCode || clientCode;
    if (this.clientCode) {
      this.getIntelligenceList(this.userId, this.clientCode, false);
    }
  }

  componentDidUpdate(prevState) {
    const { clientCode, userId } = this.props;
    if (clientCode && prevState.clientCode !== clientCode) {
      this.userId = userId || this.userId;
      this.clientCode = clientCode;
      this.setState({
        searchText: '',
      });
      this.getIntelligenceList(this.userId, clientCode, true);
    }
  }

  componentWillReceiveProps = nextProps => {
    const { intelligences } = this.props;
    if (nextProps.intelligences.length !== intelligences.length) {
      this.setState({
        intelligences: nextProps.intelligences,
        isSearching: false
      });
    }
  };

  handleNote = (cardId, hasNote) => {
    const { intelligences } = this.state;
    const temp = [...intelligences];
    const index = temp.findIndex(k => k.content_id === cardId);
    const tempobj = { ...temp[index] };
    tempobj.note_id = hasNote;
    temp[index] = tempobj;
    this.setState({ intelligences: temp });
  };

  getIntelligenceList = (userId, clientId, loader) => {
    const { getIntelligence } = this.props;
    getIntelligence(userId, clientId, loader).then(resp => {
      if (resp && resp.data) {
        const { contentDetail } = this.props;
        const intelligenceList = resp.data.data;
        this.prepareDetails(intelligenceList, contentDetail);
        this.setState({ intelligences: intelligenceList, isSearching: false });
        const payload = updateOrderPayload(intelligenceList, userId, clientId);
        updateIntelApi(payload, userId, clientId);
      } else {
        this.setState({ intelligences: [] });
      }
    });
  };

  prepareDetails = async (list, contentDetail) => {
    const { bookmarkArray } = this.state;
    const arr = {};
    list.map(async item => {
      new Promise(resolve => {
        if (item.bookmark && !bookmarkArray.includes(item.content_id)) {
          bookmarkArray.push(item.content_id);
        }
        if (item.size.trim() !== SMALL) {
          contentDetail(item.content_id, this.userId, this.clientCode).then(
            res => {
              arr[item.content_id] = res[0];
              resolve(arr);
              this.setState({ sitedetails: {} });
            }
          );
        } else {
          this.setState({ sitedetails: {} });
        }
      }).then(() => {
        this.setState(() => ({ sitedetails: arr }));
      });
    });
  };

  onSortEnd = async ({ oldIndex, newIndex }) => {
    const { intelligences } = this.state;
    const { contentDetail } = this.props;
    const updatedCards = arrayMove(intelligences, oldIndex, newIndex);
    const payload = updateOrderPayload(
      updatedCards,
      this.userId,
      this.clientCode
    );
    this.setState({ intelligences: updatedCards });
    await this.updateCard(payload);
    await this.prepareDetails(updatedCards, contentDetail);
    this.setState({ intelligences: updatedCards });
  };

  updateCardSize = (index, size) => {
    const { intelligences, sitedetails } = this.state;
    const tempSites = [...intelligences];
    const tempObj = { ...intelligences[index] };
    tempObj.size = size;
    tempSites[index] = tempObj;
    this.setState({ intelligences: tempSites });

    if (size === SMALL && sitedetails[tempObj.content_id]) {
      delete sitedetails[tempObj.content_id];
    }
    const payload = updateSizePayload(tempObj, this.userId, this.clientCode, index);
    this.updateCard(payload);
    this.pushdetails(tempObj.content_id);
  };

  pushdetails = id => {
    const { contentDetail } = this.props;
    const { sitedetails } = this.state;

    let arr = {};
    if (Object.keys(sitedetails).length) {
      arr = { ...sitedetails };
    }
    contentDetail(id, this.userId, this.clientCode).then(res => {
      const [card] = res;
      arr[id] = card;
      this.setState({ sitedetails: arr });
    });
  };

  handleBookmark = async (card, bookmarkValue) => {
    const { intelligences } = this.state;
    const { toggleBookmark, setIntelCards, toast } = this.props;
    const temp = [...intelligences];
    const id = card.content_id || card.platformcontentsk;

    const params = {
      user_id: this.userId,
      content_id: card.content_id || card.platformcontentsk,
      client: this.clientCode,
      card_type: 'INTEL',
      bookmarked: bookmarkValue
    };

    //for list
    const index = temp.findIndex(
      k => k.content_id === (card.platformcontentsk || card.content_id)
    );
    const tempobj = { ...temp[index] };
    tempobj.bookmark = !card.bookmark;
    const tempProps = [...this.props.intelligences];
    const tempIndex = tempProps.findIndex(
      k => k.content_id === (card.platformcontentsk || card.content_id)
    );
    tempProps[tempIndex] = tempobj;
    setIntelCards(tempProps);
    this.setState({ intelligences: temp });
    this.handleBookmarkArray(!bookmarkValue, id)
    await toggleBookmark(params);
    if (card.bookmark) {
      toast(UNBOOKMARKED_TEXT, true);
    } else {
      toast(BOOKMARKED_TEXT, true);
    }
  };

  handleChange = event => {
    const { value, name } = event.target;
    const str = value.replace(/^\s+/g, '');
    const { intelligences } = this.props;
    const newIntelligences = insertKeyValue(intelligences);
    const result = searchInArray(newIntelligences, str);
    let isSearching = false;
    if (result.length < 1) {
      isSearching = true;
    }
    this.setState({
      intelligences: result,
      [name]: str,
      isSearching: isSearching
    });
  };

  clearSearch = () => {
    const { intelligences } = this.props;
    this.setState({
      intelligences: intelligences,
      isSearching: false,
      searchText: ''
    });
    this.getIntelligenceList(this.userId, this.clientCode, false);
  };

  navBar = () => (
    <SubNav>
      <div className="breadcrumbs d-flex justify-content-between">
        <h2 className="title pr-2">{INTELLIGENCE}</h2>
        <div className="d-flex justify-content-end align-items-center breadcrumbs-action ">
          <SearchForm
            onChange={this.handleChange}
            value={this.state.searchText}
            clear={this.clearSearch}
          />
        </div>
      </div>
    </SubNav>
  );

  card = isSearching =>
    (isSearching ? (
      <CardNotFound text={NO_CARD} />
    ) : (
        <WelcomeSiteCard text={WELCOME_INTELLIGENCE_TEXT} />
      ));

  async updateCard(payload) {
    const { updateIntelligence, getIntelligence } = this.props;
    await updateIntelligence(payload, this.userId, this.clientCode);
    getIntelligence(this.userId, this.clientCode, false);
  }

  handleBookmarkArray = (bookmarkValue, id) => {
    let { bookmarkArray } = this.state;
    if (bookmarkValue) {
      bookmarkArray.push(id);
    } else if (bookmarkArray.includes(id) && !bookmarkValue) {
      const index = bookmarkArray.findIndex(element => element === id);
      delete bookmarkArray[index];
    }
  }

  render() {
    const {
      isOpen: { isShow },
      toggleSiteModel,
      loading: { loading }
    } = this.props;
    const {
      intelligences,
      sitedetails,
      isSearching,
      bookmarkArray
    } = this.state;
    if (!intelligences) {
      return (
        <>
          {this.navBar()}
          <ScreenLoader />
        </>
      );
    }
    return (
      <>
        {this.navBar()}
        {loading && (
          <div className="loader-full-page">
            <ScreenLoader />
          </div>
        )}
        <FullViewCard
          isOpen={isShow}
          toggleModel={toggleSiteModel}
          handleBookmark={this.handleBookmark}
        />
        {intelligences.length < 1 ? (
          this.card(isSearching)
        ) : (
            <div className="container">
              <SortableComponent
                items={intelligences}
                onSortEnd={this.onSortEnd}
                isShow={isShow}
                toggleSiteModel={toggleSiteModel}
                updateSize={this.updateCardSize}
                cardDetail={sitedetails}
                handleNote={this.handleNote}
                bookmarkArray={bookmarkArray}
                handleBookmarkArray={this.handleBookmarkArray}
              />
            </div>
          )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  intelligences: state.intelligences,
  isOpen: state.toggleSiteModel,
  loading: state.xhrStatus,
  userId: state.userId,
  clientCode: state.clientInfo.clientCode,
  hasNote: state.toggleAddNotesModel.hasNote
});

export default withAuth(
  connect(
    mapStateToProps,
    {
      getIntelligence,
      toggleSiteModel,
      updateMySites,
      contentDetail,
      toggleBookmark,
      updateIntelligence,
      setIntelCards,
      toast
    }
  )(Intelligence)
);
