import { SITE_DETAIL } from '../actions/actionType';

/**
 * Function for user details
 * @param  {Object} state
 * @param  {Object} action
 */
const initialState = [];
export const siteDetail = (state, action) => {
  const { type, payload } = action;
  if (typeof state === 'undefined') {
    return initialState;
  } else if (type === SITE_DETAIL) {
    return [...initialState, ...payload];
  } else {
    return state;
  }
};
