import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { connect } from 'react-redux';

import {
  toggleSiteModel,
  siteDetail,
  contentDetail,
  actionDetail,
  toggleShareModel,
  toggleAddNotesModel,
  toast
} from '../../redux/actions';

import { toggleBookmarkApi } from '../../redux/xhrCalls';
import {
  SMALL,
  MEDIUM,
  LARGE,
  EDIT_NOTES,
  ADD_NOTES,
  APP,
  UNBOOKMARKED_TEXT,
  BOOKMARKED_TEXT
} from '../../constants';
import { getCardType } from '../../utils';

export const fullViewButton = (fullView, isVisible, id, props) =>
  isVisible && (
    <span className="view-in-full-screen">
      <span
        className={'icon icon-full-screen-icon'}
        onClick={() => fullView(props, id)}
      />
    </span>
  );
export const favoriteButton = (className, toggle, isVisible) =>
  isVisible && (
    <a href className={className} onClick={() => toggle()}>
      Add to favorites
    </a>
  );

async function fullView(props, id) {
  const {
    card,
    toggleSiteModel,
    isOpen,
    siteDetail,
    contentDetail,
    userId,
    clientCode,
    actionDetail,
    isActionDetail,
    loadAction,
    userdetails,
    currentPage
  } = props;
  const user = userId || sessionStorage.getItem('userId');
  const client = clientCode || sessionStorage.getItem('clientCode');
  if (!card.content_id && !isActionDetail && !card.id) {
    await siteDetail(id, user, client, card.is_shared);
    toggleSiteModel(!isOpen.isShow, card.share_card_id, false, card.from_user_email);
  } else if (!isActionDetail && !card.id) {
    await contentDetail(card.content_id, user, client, true, card.is_shared);
    toggleSiteModel(!isOpen.isShow, card.share_card_id, false);
  } else {
    await actionDetail(card, userdetails);
    loadAction && loadAction(currentPage);
    toggleSiteModel(!isOpen.isShow, card.share_card_id, true);
  }
}

function CardActions(props) {
  const {
    card,
    className,
    index,
    updateSize,
    handleBookmark,
    handleToggle,
    isEnable,
    isActionDetail,
    disableforApps,
    toggleShareModel,
    toggleAddNotesModel,
    handleDelete,
    userId,
    clientCode,
    size,
    isBookamrk
  } = props;
  const user = userId || sessionStorage.getItem('userId');
  const client = clientCode || sessionStorage.getItem('clientCode');
  const [dropdownOpen, toggle] = useState(false);
  if (!card) {
    return false;
  }
  const id = card.facilitysk;
  function update(size) {
    updateSize(index, size);
  }
  const idForShare =
    card.facilitysk || card.content_id || card.platformcontentsk || card.id;
  const cardType = getCardType(card);
  const idForNotes = card.facilitysk || card.id || card.content_id;

  function handleToogleBookmark() {
    if (card.card_type || isActionDetail) {
      handleBookmark(card);
    } else {
      handleToggle(!isBookamrk);
      const params = {
        user_id: user,
        content_id: idForShare,
        client: client,
        card_type: cardType,
        bookmarked: isBookamrk
      };
      toggleBookmarkApi(params);
      if (isBookamrk) {
        toast(UNBOOKMARKED_TEXT, true);
      } else {
        toast(BOOKMARKED_TEXT, true);
      }
    }
  }
  let bookmarkValue;
  let isHome = false;
  if (card.card_type) {
    isHome = true;
    bookmarkValue = true;
  } else if (isActionDetail) {
    bookmarkValue = card.bookmark;
  } else {
    bookmarkValue = isBookamrk;
  }
  const isSharedActionCard = card.id && card.share_card_id;
  const isDisplay = () => !isEnable && !isActionDetail && !isSharedActionCard && isHome;
  return (
    <span className={className}>
      {favoriteButton(
        `icon-heart-dark ${(bookmarkValue) && 'active'}`,
        handleToogleBookmark,
        !card.is_shared
      )}
      {fullViewButton(fullView, !isEnable || isActionDetail, id, props)}
      <span className="dropdown-custom">
        <Dropdown
          isOpen={dropdownOpen}
          toggle={() => toggle(!dropdownOpen)}
          direction="left"
        >
          <DropdownToggle className="link">
            <span className="icon-dot-icon" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu custom-ui">

            {/* * Add Note is move to second phase */}
            {!disableforApps && cardType !== APP && !card.share_card_id && (
              <DropdownItem
                onClick={() =>
                  toggleAddNotesModel(
                    true,
                    idForNotes,
                    cardType,
                    card.note_id,
                    user,
                    client
                  )}
                className='d-none'
              >
                {card.note_id ? EDIT_NOTES : ADD_NOTES}
              </DropdownItem>
            )}
            <DropdownItem
              onClick={() =>
                toggleShareModel(true, idForShare, cardType, user, client)}
            >
              Share
            </DropdownItem>
            {isDisplay() && (
              <DropdownItem
                className={size === SMALL && 'active'}
                onClick={() => update(SMALL)}
              >
                Small
              </DropdownItem>
            )}
            {isDisplay() && (
              <DropdownItem
                className={size === MEDIUM && 'active'}
                onClick={() => update(MEDIUM)}
              >
                Medium
              </DropdownItem>
            )}
            {isDisplay() && (
              <DropdownItem
                className={
                  size === LARGE ? 'active desktop-view' : 'desktop-view'
                }
                onClick={() => update(LARGE)}
              >
                Large
              </DropdownItem>
            )}
            {!!card.is_shared && (
              <DropdownItem
                onClick={() =>
                  handleDelete(card.share_card_id, idForShare, user, client)}
                className="delete-btn"
              >
                Delete Card
              </DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>
      </span>
    </span>
  );
}
const mapStateToProps = state => ({
  isOpen: state.toggleSiteModel,
  userId: state.userId,
  clientCode: state.clientInfo.clientCode,
  userdetails: state.userDetails
});

export default connect(
  mapStateToProps,
  {
    toggleSiteModel,
    siteDetail,
    contentDetail,
    actionDetail,
    toggleShareModel,
    toggleAddNotesModel
  }
)(CardActions);
