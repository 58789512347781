import React, { PureComponent } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

import { GOOGLE_MAP_KEY } from '../../constants/index';

class MapContainer extends PureComponent {
  render() {
    const { latitude, longitude, google } = this.props;

    return (
      <Map
        google={google}
        zoom={14}
        style={{
          width: '100%',
          height: '100%'
        }}
        initialCenter={{
          lat: latitude,
          lng: longitude
        }}
      >
        <Marker />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAP_KEY
})(MapContainer);
