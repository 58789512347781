import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import React from 'react';

import setupInterceptors from './setupInterceptors';
import store from './redux/store';
import './assets/styles/index.scss';
import App from './components/app/App';

setupInterceptors();
render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
