import { TOAST } from '../actions/actionType';

const initialState = { message: '', status: false };
export const toast = (state, action) => {
  const { type, payload } = action;
  if (typeof state === 'undefined') {
    return initialState;
  } else if (type === TOAST) {
    return { ...state, ...payload };
  } else {
    return state;
  }
};
