import axios from 'axios';
import { ACTIONS_LIST, UPDATE_ACTION_STATUS, GET_UNREAD_ACTION_COUNT } from '../../constants';

/**
 * Function to get actions list
 */
export const getActionsApi = (payload) => axios.get(ACTIONS_LIST, { params: payload });


/**
 * Function to update action status
 */
export const updateActionStatusApi = (payload) => axios.get(UPDATE_ACTION_STATUS, { params: payload });

/**
 * Function to get count of action
 */
export const getUnreadActionCountApi = (payload) => axios.get(GET_UNREAD_ACTION_COUNT, { params: payload });
