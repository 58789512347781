import axios from 'axios';
import { GET_INTELLIGENCE, UPDATE_INTEL, GET_TABLEAU_TICKET, GET_TAB_REPORT } from '../../constants';

/**
 * Function to get intelligence cards
 */
export const getIntelligenceApi = (userId, clientId) =>
  axios.get(`${GET_INTELLIGENCE}?user_id=${userId}&client=${clientId}`);

export const updateIntelApi = (payload, userId, clientId) =>
  axios.post(`${UPDATE_INTEL}?client=${clientId}&user_id=${userId}`, payload);

export const getTableauTicketApi = (payload, userId, clientId) =>
  axios.post(`${GET_TABLEAU_TICKET}?client=${clientId}&user_id=${userId}`, payload);

export const getTabReportApi = (facilitysk, clientCode) =>
  axios.get(`${GET_TAB_REPORT}?facilitysk=${facilitysk}&clientcode=${clientCode}`);
