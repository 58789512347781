export const START_SESSION = '/StartSession';
export const GET_USER_DETAIL = '/GetUserProfile';
export const GET_MYSITE_LIST = '/GetAllMySites';
export const GET_MYSITE_FILTERS = '/GetMysiteFilters';
export const GET_MY_SITE = '/GetMySite';
export const TERMS_OF_USE = '/terms';
export const SAVEMYSITESTATE = '/SaveMySiteState';
export const GET_INTELLIGENCE = '/GetIntelligenceCards';
export const GET_HOME_CARDS = '/GetHomepageCards';
export const TOGGLE_BOOKMARK = '/ToggleBookmark';
export const GET_CONTENT = '/GetContentDetails';
export const GET_USER_CLIENTS = '/GetUserClients';
export const UPDATE_INTEL = '/SaveInsightState';
export const UPDATE_HOME_CARDS = '/SaveHomePageState';
export const ACTIONS_LIST = '/GetActionCards';
export const APPLICATIONS_LIST = '/GetApplications';
export const UPDATE_APPLICATION_CARDS = '/SaveAppState';
export const UPDATE_ACTION_STATUS = '/ActionStatus';
export const GET_UNREAD_ACTION_COUNT = '/GetUnreadActionCount';
export const SAVE_USER_DETAIL = '/SaveUserProfile';
export const GET_MODULES = '/GetUserModules';
export const TOGGLE_MODULE = '/ChangeUserModuleStatus';
export const WRITE_NOTE = '/WriteNote';
export const DELETE_NOTE = '/DeleteNote';
export const GET_NOTE = '/GetNote';
export const GET_SHARE_USERS = '/GetShareUsers';
export const SHARE_CARD = '/ShareCard';
export const DELETE_SHARE_CARD = '/DeleteShareCard';
export const UPLOAD_PROFILE_PIC = '/UploadProfilePic';
export const CHANGE_PASSWORD = '/credentials/change_password';
export const OKTA_USERS = '/api/v1/users/';
export const GET_TABLEAU_TICKET = '/GetTableauTicket';
export const GET_TAB_REPORT = '/GetMysiteTabs';
