import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Security, ImplicitCallback, SecureRoute } from '@okta/okta-react';

import MySites from '../components/pages/mysites';
import Home from '../components/pages/home/index';
import NotFound from '../components/pages/404';
import Apps from '../components/pages/applications';
import intelligence from '../components/pages/intelligence';
import actions from '../components/actions';
import Login from '../components/auth/Login';
import Footer from '../components/layout/Footer';
import Navbar from '../components/layout/Navbar';
import { BASE_URL, ROUTES } from '../constants';
import { idToken } from '../utils';
import Toast from '../components/layout/Toast';

function onAuthRequired({ history }) {
  return history;
}

function checkAuth() {
  const { pathname } = window.location;
  return !pathname.includes('/signIn') && pathname !== '/';
}

function isLogin() {
  const oktaObj = idToken('okta-token-storage');
  return oktaObj && oktaObj.idToken;
}

const Routes = props => {
  const { history } = props;
  let hasRoute = ROUTES.some(path => history.location.pathname === path);
  if (!isLogin()) hasRoute = true;
  const checkLogin = checkAuth() || isLogin();
  const getContainerClass = () =>
    `container-0  ${
    checkLogin
      ? 'container-fluid after-login-pages'
      : 'container sign-in-container'
    } `;

  return (
    <Switch>
      <Security
        issuer={`${BASE_URL}/oauth2/default`}
        client_id={'0oa4xd92stP9eqtwn2p7'}
        redirect_uri={window.location.origin + '/implicit/callback'}
        onAuthRequired={onAuthRequired}
      >
        {!hasRoute ? (
          <Route component={NotFound} />
        ) : (
            <>
              <div className="App">
                <Navbar {...props} />
                <div className={getContainerClass()}>
                  <SecureRoute path="/mysites/" exact component={MySites} />
                  <SecureRoute path="/home/" exact component={Home} />
                  <SecureRoute path="/applications/" exact component={Apps} />
                  <SecureRoute
                    path="/intelligence/"
                    exact
                    component={intelligence}
                  />
                  <SecureRoute path="/actions/" exact component={actions} />
                  <Route
                    path="/signIn"
                    exact
                    render={() => <Login baseUrl={BASE_URL} />}
                  />
                  <Route
                    path="/"
                    exact
                    render={() => <Redirect to="/signIn" />}
                  />
                  <Route
                    path="/signIn*"
                    exact
                    render={() => <Redirect to="/signIn" />}
                  />
                  <Route
                    path="/implicit/callback"
                    exact
                    component={ImplicitCallback}
                  />
                </div>
              </div>
              <Toast />
              {checkAuth() && <Footer />}
            </>
          )}
      </Security>
    </Switch>
  );
};
export default Routes;
