import axios from 'axios';
import { GET_HOME_CARDS, UPDATE_HOME_CARDS } from '../../constants';

/**
 * Function to get home cards
 */
export const getHomeCardsApi = (userId, client) =>
  axios.get(`${GET_HOME_CARDS}?user_id=${userId}&client=${client}&page=1&limit=1`);

/**
* Function to update home cards
*/
export const updateHomeApi = (payload, userId, clientId) =>
  axios.post(`${UPDATE_HOME_CARDS}?client=${clientId}&user_id=${userId}`, payload);
