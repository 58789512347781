import { getMySite } from '../xhrCalls';
import { SITE_DETAIL } from './actionType';
import { beginAjaxCall, endAjaxCall } from './xhrSatus';

/**
 * Action for get mysite detail
 */
export const siteDetail = (facilityId, userId, clientId, shared) => (dispatch) => {
  dispatch(beginAjaxCall());
  const isShared = shared ? 1 : 0;
  return getMySite(facilityId, userId, clientId, isShared)
    .then(response => {
      dispatch(endAjaxCall());
      dispatch({
        type: SITE_DETAIL,
        payload: response.data.data
      });
      return response.data.data;
    })
    .catch(error => {
      dispatch(endAjaxCall());
      console.error(error);
      return error;
    });
}
