import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';

import {
  updateApp,
  getApps,
  toggleBookmark,
  setAppsCards
} from '../../../redux/actions';

import {
  updateOrderPayload,
  WELCOME_APPS_TEXT,
  APPS,
  searchInArray,
  NO_CARD,
  insertKeyValue
} from '../../../utils';
import WelcomeSiteCard from '../../cards/WelcomeCard';
import CardNotFound from '../../cards/CardNotFound';
import { ScreenLoader } from '../../layout/ScreenLoader';
import SortableComponent from '../../cards/Sortable';
import SubNav, { SearchForm } from '../../layout/SubNav';

class Apps extends Component {
  constructor(props) {
    super(props);
    this.state = { searchText: '', bookmarkArray: [] };
    this.userId = sessionStorage.getItem('userId');
    this.clientCode = sessionStorage.getItem('clientCode');
  }

  componentDidMount() {
    const { clientCode, userId } = this.props;
    this.userId = userId || this.userId;
    this.clientCode = this.clientCode || clientCode;
    if (this.clientCode) {
      this.getAppsList(this.userId, this.clientCode);
    }
  }

  componentDidUpdate(prevState) {
    const { clientCode, userId } = this.props;
    if (clientCode && prevState.clientCode !== clientCode) {
      this.userId = userId || this.userId;
      this.clientCode = clientCode;
      this.setState({
        searchText: '',
      });
      this.getAppsList(this.userId, clientCode);
    }
  }

  getAppsList = (userId, clientId) => {
    const { getApps } = this.props;
    getApps(userId, clientId, true).then(resp => {
      if (resp && resp.data) {
        const appsList = resp.data.data;
        this.getBookMarkArray(appsList);
        this.setState({ apps: appsList, isSearching: false });
        const payload = updateOrderPayload(appsList, userId, clientId);
        this.updateCard(payload);
      } else {
        this.setState({ apps: [] });
      }
    });
  };

  getBookMarkArray = (list) => {
    const { bookmarkArray } = this.state;
    list.map(item => {
      if (item.bookmark && !bookmarkArray.includes(item.content_id)) {
        return bookmarkArray.push(item.content_id);
      }
      return true;
    })
  }

  onSortEnd = async ({ oldIndex, newIndex }) => {
    const { apps } = this.state;
    const updatedCards = arrayMove(apps, oldIndex, newIndex);
    const payload = updateOrderPayload(
      updatedCards,
      this.userId,
      this.clientCode
    );
    this.setState({ apps: updatedCards });
    await this.updateCard(payload);
  };

  handleBookmark = async card => {
    const { apps } = this.state;
    const { toggleBookmark, setAppsCards } = this.props;
    const temp = [...apps];
    const params = {
      user_id: this.userId,
      content_id: card.content_id,
      client: this.clientCode,
      card_type: 'APP',
      bookmarked: card.bookmark
    };
    const index = temp.findIndex(k => k.content_id === card.content_id);
    const tempobj = { ...temp[index] };
    tempobj.bookmark = !card.bookmark;
    const tempProps = [...this.props.apps];
    const tempIndex = tempProps.findIndex(
      k => k.content_id === card.content_id
    );
    tempProps[tempIndex] = tempobj;
    setAppsCards(tempProps);
    temp[index] = tempobj;
    this.setState({ apps: temp });
    await toggleBookmark(params);
  };

  handleChange = event => {
    const { value, name } = event.target;
    const str = value.replace(/^\s+/g, '');
    const { apps } = this.props;
    const newApps = insertKeyValue(apps);
    const result = searchInArray(newApps, str);
    let isSearching = false;
    if (result.length < 1) {
      isSearching = true;
    }
    this.setState({
      apps: result,
      [name]: str,
      isSearching: isSearching
    });
  };

  clearSearch = () => {
    const { apps } = this.props;
    this.setState({
      apps: apps,
      isSearching: false,
      searchText: ''
    });
  };

  navBar = () => (
    <SubNav>
      <div className="breadcrumbs d-flex justify-content-between">
        <h2 className="title pr-2">{APPS}</h2>
        <div className="d-flex justify-content-end align-items-center breadcrumbs-action ">
          <SearchForm
            onChange={this.handleChange}
            value={this.state.searchText}
            clear={this.clearSearch}
          />
        </div>
      </div>
    </SubNav>
  );

  card = isSearching =>
    (isSearching ? (
      <CardNotFound text={NO_CARD} />
    ) : (
        <WelcomeSiteCard text={WELCOME_APPS_TEXT} />
      ));

  handleBookmarkArray = (bookmarkValue, id) => {
    const { bookmarkArray } = this.state;
    if (bookmarkValue) {
      bookmarkArray.push(id);
    } else if (bookmarkArray.includes(id) && !bookmarkValue) {
      const index = bookmarkArray.findIndex(element => element === id);
      delete bookmarkArray[index];
    }
  }

  async updateCard(payload) {
    const { updateApp, clientCode } = this.props;
    await updateApp(payload, this.userId, clientCode);
  }

  render() {
    const { apps, isSearching, bookmarkArray } = this.state;
    const {
      loading: { loading }
    } = this.props;
    if (!apps) {
      return (
        <>
          {this.navBar()}
          <ScreenLoader />
        </>
      );
    }
    return (
      <>
        {this.navBar()}
        {loading && (
          <div className="loader-full-page">
            <ScreenLoader />
          </div>
        )}
        {apps.length < 1 ? (
          this.card(isSearching)
        ) : (
            <div className="container">
              <SortableComponent
                items={apps}
                onSortEnd={this.onSortEnd}
                handleBookmark={this.handleBookmark}
                disableforApps
                isEnable
                bookmarkArray={bookmarkArray}
                handleBookmarkArray={this.handleBookmarkArray}
              />
            </div>
          )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  apps: state.Apps,
  userId: state.userId,
  clientCode: state.clientInfo.clientCode,
  loading: state.xhrStatus,
});

export default withAuth(
  connect(
    mapStateToProps,
    {
      getApps,
      updateApp,
      toggleBookmark,
      setAppsCards
    }
  )(Apps)
);
