import React, { useState } from 'react';
import { CardMedia } from './CardsMedia';
import { getCardType } from '../../utils';
import { APP, SITE, NUMBER } from '../../constants';
import IframeModal from './IframeModal';

export default function SmallCard(props) {
  const { card, drag, isOn, cardLoading, handleBookmarkArray, bookmarkArray } = props;
  const id =
    card.facilitysk || card.content_id || card.platformcontentsk || card.id;
  const hasBookmark = bookmarkArray.includes(id);
  const [isBookamrk, toggleBookmark] = useState(hasBookmark);
  const [isOpen, setIsOpen] = useState(false);

  const onCardClick = (iframe, url) => {
    iframe === NUMBER.One ? setIsOpen(true) : window.open(url, '__blank');
  };
  const toggleModel = () => setIsOpen(false);

  function handleToggle(value) {
    toggleBookmark(value);
    handleBookmarkArray(value, id);
  }

  const region = card.region ? card.region : '';
  const address = card.address1 ? card.address1 : '';
  const address2 = card.city ? address + ' ' + card.city + ' ' + region : '';
  const cardType = getCardType(card);
  return (
    <div
      className={
        cardLoading.cardLoader &&
          (cardLoading.cardLoader === card.share_card_id ||
            (cardLoading.cardLoader === id && !card.share_card_id))
          ? 'card-animation card'
          : 'card'
      }
    >
      {isOn && drag}
      {(cardType === APP) ?
        <div onDoubleClick={() => onCardClick(card.iframe, card.url)}>
          <CardMedia
            {...props}
            isBookamrk={isBookamrk}
            handleToggle={handleToggle}
          />
        </div> :
        <CardMedia
          {...props}
          isBookamrk={isBookamrk}
          handleToggle={handleToggle}
        />}
      <div className="card-body">
        {(cardType === APP) && (
          <>
            <h5 className="card-title iframe-title" onClick={() => onCardClick(card.iframe, card.url)}>
              {card.title}
            </h5>
            <IframeModal
              isOpen={isOpen}
              toggleModel={toggleModel}
              url={card.url}
              iframeClass="iframeClass"
              iframeModal="iframe-modal"
            />
          </>
        )}
        {(cardType !== APP && cardType !== SITE) && (
          <h5 className="card-title">{card.title}</h5>
        )}
        <p className="card-text mr-4">{address2.slice(NUMBER.Zero, NUMBER.Hundred)}</p>
      </div>
    </div>
  );
}
