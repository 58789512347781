import axios from 'axios';
import { GET_SHARE_USERS, SHARE_CARD, DELETE_SHARE_CARD } from '../../constants';

/**
 * Function to get share user list
 */
export const getShareUserApi = (payload) =>
  axios.get(GET_SHARE_USERS, { params: payload });

export const shareCardApi = (payload, userId, clientCode) =>
  axios.post(`${SHARE_CARD}?client=${clientCode}&user_id=${userId}`, payload);

export const deleteShareCardApi = (payload) =>
  axios.get(DELETE_SHARE_CARD, { params: payload });
