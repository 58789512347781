import axios from 'axios';
import { GET_MODULES, TOGGLE_MODULE } from '../../constants';

/**
 * Function to get mySite's detail
 */
export const getModules = userId =>
  axios.get(`${GET_MODULES}?&user_id=${userId}`);

export const toggleModule = payload =>
  axios.get(TOGGLE_MODULE, { params: payload });
