import React from 'react';

import SmallCard from './SmallCard';
import MediumCard from './MediumCard';
import LargeCard from './LargeCard';
import { getMobileOperatingSystem, MEDIUM, LARGE } from '../../utils';

export default function RenderCard(props) {
  const {
    card,
    isOpen,
    toggle,
    updateSize,
    ind,
    isOn,
    setCard,
    carddetails,
    key,
    handleBookmark,
    disableforApps,
    isEnable,
    isActionDetail,
    isHome,
    children,
    handleDelete,
    cardLoading,
    handleBookmarkArray,
    bookmarkArray
  } = props;
  const isMobile = getMobileOperatingSystem();
  const cardSize = card.size && card.size.trim();
  let className = `${
    isOn && isMobile ? 'card-move-position move-for-mobile` ' : ''
    }`;
  let comp;
  const obj = {
    card,
    toggleModel: toggle,
    isOpen,
    updateSize,
    index: ind,
    isOn,
    carddetails,
    setCard,
    size: cardSize,
    handleBookmark,
    disableforApps,
    isEnable,
    isActionDetail,
    isHome,
    handleDelete,
    drag: children,
    cardLoading,
    handleBookmarkArray,
    bookmarkArray
  };
  if (cardSize === MEDIUM) {
    className += ' medium-card';
    comp = MediumCard(obj);
  } else if (cardSize === LARGE && !isMobile) {
    className += ' large-card';
    comp = LargeCard(obj);
  } else {
    className += ' small-card';
    comp = SmallCard(obj);
  }
  return (
    <li className={`card-view ${className} `} key={key} onDoubleClick={() => props.handleDoubleClick(card, cardSize)}>
      {comp}
    </li>
  );
}
