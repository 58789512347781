import React, { useState } from 'react';

import { CardMedia } from './CardsMedia';
import { Analytics } from './Analytics';
import CardActions from './CardActions';
import CardInfo from './CardInfo';

export default function MediumCard(props) {
  const {
    card,
    type,
    index,
    updateSize,
    carddetails,
    size,
    drag,
    isOn,
    handleDelete,
    cardLoading,
    handleBookmarkArray,
    bookmarkArray
  } = props;
  const id =
    card.facilitysk || card.content_id || card.platformcontentsk || card.id;
  const hasBookmark = bookmarkArray.includes(id);
  const [isBookamrk, toggleBookmark] = useState(hasBookmark);

  function handleToggle(value) {
    toggleBookmark(value);
    handleBookmarkArray(value, id);
  }
  return (
    <div
      className={
        cardLoading.cardLoader &&
          (cardLoading.cardLoader === card.share_card_id ||
            (cardLoading.cardLoader === id && !card.share_card_id))
          ? 'card-animation card'
          : 'card'
      }
    >
      {isOn && drag}
      <div className="row mr-0 ml-0 medium-card-media-row">
        <div className="col-md-6 pl-0 pr-0">
          <CardMedia
            card={card}
            size={size}
            type={type}
            updateSize={updateSize}
            index={index}
            isBookamrk={isBookamrk}
            handleToggle={handleToggle}
            handleDelete={handleDelete}
          />
        </div>
        <div className="col-md-6 pl-0 pr-0">
          <div className="card-body pt-1">
            <h5 className="card-title">{card.title} </h5>
            <div className="media-action justify-content-end pt-1 pb-0 pr-0">
              <CardActions
                className="action dropdown desktop-view"
                {...props}
                isBookamrk={isBookamrk}
                handleToggle={handleToggle}
              />
            </div>
            <p className="card-text">
              {`${card.address1 || ''} ${card.city || ''} ${card.region || ''}`}
            </p>
          </div>
          {card.facilitysk && <div className="divider" />}
          <CardInfo card={card} />
        </div>
      </div>
      <Analytics analytics={carddetails} type={type} card={card} size={size} />
    </div>
  );
}
