import React from 'react'

export default function WelcomeCard({text}) {
  return (
    <div className="container">
      <div className="no-record-page d-flex justify-content-center align-items-center flex-column h-50">
        <figure className="mb-0">
          <img className="no-page" src="/assets/images/no-data-found.png"
            alt="No Data Found" />
        </figure>
        <h3>{text}</h3>
        {/* <p>Any new sites will appear here</p> */}
      </div>
    </div>
  )
}
