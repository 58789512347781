import React from 'react'

export default function NotFound() {
  return (
    <div className="container">
      <div className="no-record-page d-flex justify-content-center align-items-center flex-column vh-100 page-404">
        <figure className="mb-0">
          <img className="no-page" src="/assets/images/no-data-found.png"
            alt="No Data Found" />
        </figure>
        <h3>404 Page Not Found</h3>
        <p className="mb-0 mt-4"><a href="/home" className="btn primary">home</a></p>
      </div>
    </div>
  )
}
