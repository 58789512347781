import { getUserClientApi } from '../xhrCalls';
import { USER_CLIENT, CLIENT_CODE } from './actionType';

/**
 * Action for get user client
 */
export const getUserClient = payload => dispatch =>
  getUserClientApi(payload)
    .then(response => {
      dispatch({
        type: USER_CLIENT,
        payload: response.data.data
      });
      return response.data.data;
    })
    .catch(error => {
      console.error(error);
      return error;
    });

/**
 * Action for toggle client
 */
export const toggleClient = payload => dispatch => {
  sessionStorage.setItem('clientCode', payload.clientCode);
  sessionStorage.setItem('clientName', payload.clientName);
  sessionStorage.setItem('clientImage', payload.clientLogo);
  dispatch({ type: CLIENT_CODE, payload: payload });
};
