import { HOME } from '../actions/actionType';

/**
 * Function for user details
 * @param  {Object} state
 * @param  {Object} action
 */ const initialState = [];
export const homeCards = (state, action) => {
  const { type, payload } = action;
  if (typeof state === 'undefined') {
    return initialState;
  } else if (type === HOME) {
    return [...payload];
  } else {
    return state;
  }
};
