import { getMySite } from '../xhrCalls';
import { MY_SITE } from './actionType';

/**
 * Action for get mysite detail
 */
export const getSite = (facilityId, userId, clientId, shared) => (dispatch) => {
  const isShared = shared ? 1 : 0;
  return getMySite(facilityId, userId, clientId, isShared)
    .then(response => {
      dispatch({
        type: MY_SITE,
        payload: response.data.data
      });
      return response.data.data;
    })
    .catch(error => {
      console.error(error);
      return error;
    });
};
