import React, { Component } from 'react';
import {
  SortableContainer,
  SortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import { withAuth } from '@okta/okta-react';
import { connect } from 'react-redux';

import RenderCard from './renderCard';
import { getMobileOperatingSystem, APP, ACTION, SMALL } from '../../utils';
import {
  toggleSorting,
  toggleAddNotesModel,
  toggleSiteModel,
  siteDetail,
  contentDetail,
  actionDetail
} from '../../redux/actions';
import SortableToggleButton from '../layout/SortableToggleButton';
import { Image } from '../layout/Image';
import AddNotes from '../layout/AddNotes';

const DragHandle = sortableHandle(props => {
  if (props.isOn) {
    return (
      <span className="drag-cursor">
        <Image src="/assets/images/svgicons/move-icon.svg" />
      </span>
    );
  }
  return <span />;
});
const SortableItem = SortableElement(props => (
  <RenderCard {...props}>
    <DragHandle isOn={props.isOn} />
  </RenderCard>
));

export const SortableList = SortableContainer(props => (
  <ul className="row">
    {props.isAddNote.isAddNoteOpen && (
      <AddNotes
        isOpen={props.isAddNote.isAddNoteOpen}
        toggleModel={props.toggleAddNotesModel}
        {...props}
      />
    )}
    {props.items.map((item, index) => {
      const id = item.facilitysk || item.content_id;
      const obj = props.cardDetail && props.cardDetail[id];
      return (
        <SortableItem
          key={Math.random()}
          index={index}
          ind={index}
          card={item}
          carddetails={obj}
          isEnable={item.card_type === APP}
          isActionDetail={item.card_type === ACTION}
          {...props}
        />
      );
    })}
  </ul>
));

class SortableComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      container: ''
    };
  }

  setContainerNode = node => {
    this.setState({ container: node });
  };

  handleDoubleClick = async (card, cardSize) => {
    const isAction = card.text;
    let size;
    if (isAction || cardSize === SMALL) {
      size = SMALL;
    }
    if (size !== SMALL) {
      return false;
    }
    const {
      userId,
      clientCode,
      toggleSiteModel,
      isOpen,
      siteDetail,
      contentDetail,
      actionDetail,
      userdetails
    } = this.props;
    const user = userId || sessionStorage.getItem('userId');
    const client = clientCode || sessionStorage.getItem('clientCode');
    if (!card.content_id && !card.id) {
      await siteDetail(card.facilitysk, user, client, card.is_shared);
      toggleSiteModel(!isOpen.isShow, card.share_card_id, false, card.from_user_email);
    } else if (!card.id) {
      await contentDetail(card.content_id, user, client, true, card.is_shared);
      toggleSiteModel(!isOpen.isShow, card.share_card_id, false);
    } else {
      await actionDetail(card, userdetails);
      toggleSiteModel(!isOpen.isShow, card.share_card_id, true);
    }
    return true;
  };

  render() {
    const {
      isOn: { isOn },
      toggleSorting
    } = this.props;
    const { container } = this.state;
    return (
      <>
        {SortableToggleButton(getMobileOperatingSystem, toggleSorting, isOn)}
        <div
          className="grid-view"
          id="CustomHelperContainer"
          ref={this.setContainerNode}
        >
          <SortableList
            {...this.props}
            axis="xy"
            distance={2}
            useWindowAsScrollContainer
            helperClass="card-move-position"
            isOn={isOn}
            handleDoubleClick={this.handleDoubleClick}
            helperContainer={container}
            useDragHandle={getMobileOperatingSystem()}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  homeCards: state.homeCards,
  isOpen: state.toggleSiteModel,
  isOn: state.enableSorting,
  loading: state.xhrStatus,
  cardLoading: state.cardAjaxStatus,
  userId: state.userId,
  client: state.client,
  isAddNote: state.toggleAddNotesModel,
  userdetails: state.userDetails,
  clientCode: state.clientInfo.clientCode
});

export default withAuth(
  connect(
    mapStateToProps,
    {
      toggleSorting,
      toggleAddNotesModel,
      toggleSiteModel,
      siteDetail,
      contentDetail,
      actionDetail
    }
  )(SortableComponent)
);
