import { USER_DETAIL, PROFILE_PIC } from '../actions/actionType';

/**
 * Function for user details
 * @param  {Object} state
 * @param  {Object} action
 */

const initialState = { image: 'assets/images/default-profile-pic.svg' };
export const userDetails = (state, action) => {
  const { type, payload } = action;
  if (typeof state === 'undefined') {
    return initialState;
  } else if (type === USER_DETAIL) {
    return { ...initialState, ...payload };
  } else {
    return state;
  }
};

const initialValue = '';
export const profilePic = (state, action) => {
  const { type, payload } = action;
  if (typeof state === 'undefined') {
    return initialValue;
  } else if (type === PROFILE_PIC) {
    return payload;
  } else {
    return state;
  }
};
