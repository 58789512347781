import {
  BEGIN_AJAX_CALL,
  END_AJAX_CALL,
  BEGIN_BUTTON_LOADER,
  END_BUTTON_LOADER,
  BEGIN_CARD_LOADER,
  END_CARD_LOADER
} from './actionType';

export const beginAjaxCall = () => ({ type: BEGIN_AJAX_CALL, loading: true });
export const endAjaxCall = () => ({ type: END_AJAX_CALL, loading: false });
/**
 * Action for handle button Ajax call
 */
export const beginButtonLoader = () => ({
  type: BEGIN_BUTTON_LOADER,
  buttonLoader: true
});

export const endButtonLoader = () => ({
  type: END_BUTTON_LOADER,
  buttonLoader: false
});

export const beginCardLoader = cardId => ({
  type: BEGIN_CARD_LOADER,
  cardLoader: cardId
});

export const endCardLoader = () => ({
  type: END_CARD_LOADER,
  cardLoader: false
});
