import { getTermsUrlApi } from '../xhrCalls';
import { TERMS } from './actionType';

/**
 * Action for get terms url
 */
export const getTermsUrl = () => dispatch => {
  return getTermsUrlApi()
    .then(response => {
      dispatch({
        type: TERMS,
        payload: response.data.data.privacy
      });
    })
    .catch(error => {
      console.error(error);
    });
};
