import { SITE, ACTION, INTEL, APP, NUMBER } from '../constants';
/**
 * Function for set value
 * @param {string} field
 * @param {string} value
 */
export * from '../constants';
export const setItem = (field, value) => localStorage.setItem(field, value);
export const getItem = key => localStorage.getItem(key);
export const idToken = key =>
  JSON.parse(getItem(key)) && JSON.parse(getItem(key)).idToken;
export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'IOS';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  return null;
};

export const updateOrderPayload = (sites, userId, clientId, isHome) => {
  const payload = { cards: [] };
  if (sites) {
    payload.cards = sites.map((site, index) => ({
      user_id: userId,
      client: clientId,
      content_id: site.facilitysk || site.content_id || site.id,
      size: site.size ? site.size : 'S',
      loc_order: index + 1,
      card_type: site.card_type,
      is_shared: site.is_shared,
      share_card_id: site.share_card_id || 'NULL',
      home_card_id: site.home_card_id,
      updated_at: new Date()
    }));
  }
  return payload;
};

export const updateSizePayload = (site, userId, clientId, index) => {
  const payload = { cards: [] };
  if (site) {
    payload.cards.push({
      user_id: userId,
      client: clientId,
      content_id: site.facilitysk || site.content_id,
      size: site.size,
      loc_order: index + 1,
      card_type: site.card_type,
      home_card_id: site.home_card_id,
      share_card_id: site.share_card_id || 'NULL',
      is_shared: site.is_shared
    });
  }
  return payload;
};

export const getFirstName = name => name && name.split(' ')[0];

export const toTitleCase = str =>
  str &&
  str.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

export const truncateString = (str, index) => {
  if (str.length > index) {
    return `${str.slice(0, index)}...`;
  }
  return str;
};

export const convertDate = date => {
  const dateObj = new Date(date);
  const day = dateObj.getDay();
  const month = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  return `${month[dateObj.getMonth()]} ${
    day < 10 ? '0' + day : day
    }, ${dateObj.getFullYear()}`;
};

export const SeverityOptions = [
  { value: 'All', label: 'All' },
  { value: 'Low', label: 'Low' },
  { value: 'Medium', label: 'Medium' },
  { value: 'High', label: 'High' }
];

export const groupBy = key => array =>
  array &&
  array.reduce(
    (objectsByKeyValue, obj) => ({
      ...objectsByKeyValue,
      [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj)
    }),
    {}
  );

export const insertKeyValue = array => array.map(val => (val.address1 ? val : { ...val, address1: '', city: '' }));

export const searchInArray = (array, value) =>
  array.filter(
    ({ title, address1, city }) =>
      (title || address1) && (title.toLowerCase().includes(value.trim().toLowerCase())
        || (`${address1} ${city}`).toLowerCase().includes(value.trim().toLowerCase()))
  );

export const filterSite = (array, obj) => array.filter(site =>
  obj.some(f => f.value === site.type || f.value === site.statecode || f.value === site.region));

const phoneNumberRejex = /^[0-9-]+$/;
export const phoneNumberValidation = value => phoneNumberRejex.test(value);

export const getCardType = card => {
  if (card.facilitysk) {
    return SITE;
  } else if (card.content_id || card.platformcontentsk) {
    return card.url ? APP : INTEL;
  } else if (card.id) {
    return ACTION;
  }
  return false;
};

export const setStorageTime = () => localStorage.setItem('storagetime', new Date().getTime());

export const storageTimeDiff = (props) => {
  const localStorageTime = localStorage.getItem('storagetime')
  const currentTime = new Date().getTime();
  const timeDifference = currentTime - localStorageTime;
  if (localStorageTime && timeDifference > NUMBER.Three * NUMBER.Thousand) {
    localStorage.removeItem('storagetime');
    props.logOut();
    props.auth.logout('/signIn')
      .catch((error) => {
        props.history && props.history.push('/signIn');
        throw error;
      });
    window.location.replace('/signIn');
  } else {
    setStorageTime();
  }
};

export const insertId = (dataList) => {
  const map = { title: 'Site Location' };
  dataList.unshift(map);
  for (let i = NUMBER.Zero, length = dataList.length; i < length; i++) {
    dataList[i].id = (i + NUMBER.One);
  }
  return dataList;
};
