import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScrollMenu from 'react-horizontal-scrolling-menu';

import { ScreenLoader } from '../layout/ScreenLoader';
import CardActions from './CardActions';
import CardActionsFullView from './cardActionFullView';
import MapContainer from '../layout/MapContainer';
import { getTableauTicket } from '../../redux/actions';
import { insertId } from '../../utils';
import { getTabReportApi } from '../../redux/xhrCalls/intelligence';
import { NUMBER } from '../../constants';

function createMarkup(html) {
  return { __html: html };
}

function getFramedata(isEnable, card, analytics, isMobileOrTab) {
  if (analytics) {
    if (isEnable && !isMobileOrTab) {
      if (card.platformcontentsk) {
        return (
          analytics.data4 && analytics.data4[0].fullscreen_tableau_report_url
        );
      } else {
        return (
          analytics.data4 && analytics.data4[0].fullscreen_energy_dashboard_url
        );
      }
    } else if (card.platformcontentsk || card.content_id) {
      return analytics.data3 && analytics.data3[0].large_tableau_report_url;
    } else {
      return (
        analytics.data3 && analytics.data3[0].large_card_energy_dashboard_url
      );
    }
  } else return false;
}

class TablueReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabUrl: '',
      energyAuthUrl: '',
      loading: true,
      tabList: [],
      currentTab: {},
      tabLength: NUMBER.Zero,
      alignCenter: false,
      clickWhenDrag: false,
      dragging: true,
      hideArrows: true,
      hideSingleArrow: true,
      scrollToSelected: false,
      translate: 0,
      transition: 0.3,
      wheel: true
    };
    this.menu = null;
  }

  componentDidMount() {
    this.loadTabluereport();
    if (this.props.card.facilitysk !== undefined) {
      this.tabReport();
    }
  }

  componentDidUpdate = async prevState => {
    const { tabUrl } = this.state;
    if (tabUrl === '') {
      this.loadTabluereport();
    }
  };

  tabReport = () => {
    getTabReportApi(this.props.card.facilitysk, this.props.clientCode)
      .then(resp => {
        const data = insertId(resp.data);
        this.setState({
          tabList: data,
          currentTab: data[0],
          tabLength: data.length,
          loading: false
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  async loadTabluereport() {
    const {
      userId,
      clientCode,
      analytics,
      getTableauTicket,
      isEnable,
      isMobileOrTab
    } = this.props;
    const user = userId || sessionStorage.getItem('userId');
    const client = clientCode || sessionStorage.getItem('clientCode');
    const card = analytics && analytics.data1[0];
    if (isEnable && !isMobileOrTab) {
      if (analytics && analytics.data4 && analytics.data4[0]) {
        let url;
        if (card.platformcontentsk) {
          url = analytics.data4[0].fullscreen_tableau_report_url;
        } else {
          url = analytics.data4[0].fullscreen_energy_dashboard_url;
        }
        const obj = { report_url: url };
        const payload = { data: [obj] };
        getTableauTicket(payload, user, client)
          .then(resp => {
            this.setState({
              tabUrl: resp.data.data[0].tableau_report,
              loading: false
            });
          })
          .catch(error => {
            console.error(error);
          });
      }
    } else if (analytics && analytics.data3 && analytics.data3[0]) {
      let url;
      if (card.platformcontentsk) {
        url = analytics.data3[0].large_tableau_report_url;
      } else {
        url = analytics.data3[0].large_card_energy_dashboard_url;
      }
      const obj = { report_url: url };
      const payload = { data: [obj] };
      getTableauTicket(payload, user, client)
        .then(resp => {
          this.setState({
            tabUrl: resp.data.data[0].tableau_report,
            loading: false
          });
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  render() {
    const { analytics, isEnable, className, card, type, size, index, updateSize, handleBookmark,
      isHome, share_card_id, handleDelete, isMobileOrTab, isBookamrk, handleToggle
    } = this.props;
    const { tabUrl, tabList, tabLength, currentTab, loading, alignCenter, clickWhenDrag, dragging,
      hideArrows, hideSingleArrow, scrollToSelected, translate, transition, wheel, energyAuthUrl
    } = this.state;

    const handleSelectTab = tab => {
      this.setState({
        currentTab: tab,
        energyAuthUrl: ''
      });
      if (tab.authorized_url) {
        getTabReportApi(card.facilitysk, this.props.clientCode)
          .then(res => {
            this.setState({
              energyAuthUrl: res.data.find(element => element.title === tab.title).authorized_url
            });
          })
          .catch(error => {
            throw error;
          });
      }
    };

    const Menu = (list, selectedTab) =>
      list.map(el => {
        const { title } = el;
        return <button key={title} className={`menu-item tab ${selectedTab.id === el.id ? 'active' : ''}`} onClick={() => handleSelectTab(el)}>{title}</button>;
      });

    const ArrowLeft = <div className="arrow-prev" />;
    const ArrowRight = <div className="arrow-next" />;
    this.menuItems = Menu(tabList, currentTab);
    const menu = this.menuItems;

    const analyticsdata = analytic => analytic && analytic.data1;
    const iframeData = getFramedata(isEnable, card, analytics, isMobileOrTab);

    const iframeUrl = () => {
      if (card.facilitysk === undefined && tabUrl) {
        return `<iframe src=${tabUrl} />`;
      } else if (currentTab.url && currentTab.authorized_url) {
        if (energyAuthUrl === '') {
          return 'Loading...';
        } else {
          return `<iframe src=${energyAuthUrl} />`;
        }
      } else if (currentTab.url && !currentTab.authorized_url) {
        return `<iframe src=${currentTab.url} />`;
      } else if (currentTab.url === undefined) {
        return (
          <div className="map-view">
            <MapContainer
              latitude={analyticsdata && analytics.data1[0].latitude}
              longitude={analyticsdata && analytics.data1[0].longitude}
              address={analyticsdata && analytics.data1[0].address1}
            />
          </div>
        );
      } else {
        return '';
      }
    };

    if (analytics === undefined) {
      return (
        <div className={className}>
          {!isEnable && (
            <div className="media-action justify-content-end pt-1 pb-0">
              <CardActions
                card={card}
                type={type}
                size={size}
                className="action dropdown desktop-view"
                index={index}
                updateSize={updateSize}
                isEnable={isEnable}
                handleDelete={handleDelete}
                handleBookmark={handleBookmark}
                handleToggle={handleToggle}
              />
            </div>
          )}
          <div className="energy-section">
            <ScreenLoader />
          </div>
        </div>
      );
    }

    const iframeTabUrl = () => {
      if (iframeUrl() !== '') {
        return iframeUrl();
      } else {
        return '<div>URL not available</div>';
      }
    };

    return (
      <div className={className}>
        <ScrollMenu
          alignCenter={alignCenter}
          arrowLeft={ArrowLeft}
          arrowRight={ArrowRight}
          clickWhenDrag={clickWhenDrag}
          data={menu}
          dragging={dragging}
          hideArrows={hideArrows}
          hideSingleArrow={hideSingleArrow}
          scrollToSelected={scrollToSelected}
          transition={+transition}
          translate={translate}
          wheel={wheel}
        />
        {!loading && (
          <div className="energy-for-iframe">
            {card.platformcontentsk && (
              <div className="row mt-70">
                <div className="col-10">
                  <h5 className="card-title m-0">{card.title}</h5>
                </div>
                <div className="col-2">
                  <div className="media-action justify-content-end pt-0 pr-3 pb-0">
                    <CardActionsFullView
                      card={card}
                      type={type}
                      size={size}
                      className="action dropdown intel-actions desktop-view"
                      index={index}
                      updateSize={updateSize}
                      isEnable={isEnable}
                      share_card_id={share_card_id}
                      handleBookmark={handleBookmark}
                      handleDelete={handleDelete}
                      isHome={isHome}
                    />
                  </div>
                </div>
              </div>
            )}
            {!isEnable && (
              <div className="media-action justify-content-end pt-1 pb-0">
                <CardActions
                  card={card}
                  type={type}
                  size={size}
                  className="action dropdown desktop-view"
                  index={index}
                  updateSize={updateSize}
                  handleDelete={handleDelete}
                  isBookamrk={isBookamrk}
                  handleBookmark={handleBookmark}
                  handleToggle={handleToggle}
                />
              </div>
            )}
            {tabLength > NUMBER.Zero || iframeData ? (
              <>
                {(currentTab.url === undefined && card.facilitysk) && (iframeTabUrl())}
                {(currentTab.url !== undefined || card.facilitysk === undefined) && (
                  <div
                    className="energy-section"
                    dangerouslySetInnerHTML={createMarkup(iframeTabUrl())}
                  />
                )}
              </>
            ) : (
              <div className="map-view">
                <MapContainer
                  latitude={analyticsdata && analytics.data1[0].latitude}
                  longitude={analyticsdata && analytics.data1[0].longitude}
                  address={analyticsdata && analytics.data1[0].address1}
                  />
              </div>
              )}
          </div>
        )}
        {loading && <ScreenLoader />}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  mySite: state.siteDetail,
  userId: state.userId,
  clientCode: state.clientInfo.clientCode
});

export default connect(
  mapStateToProps,
  { getTableauTicket }
)(TablueReport);
