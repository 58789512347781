import React from 'react';
import { connect } from 'react-redux';

import NavItems from './NavItems';
import { toggleSorting } from '../../redux/actions';
import { SelectField } from './formElements';
import { SeverityOptions } from '../../utils';

export const SearchForm = ({ onChange, value, clear }) => (
  <div className=" user-search search-form">
    <div className="form-group">
      <input
        className="form-control"
        placeholder="Search..."
        onChange={e => onChange(e)}
        value={value}
        type="text"
        name="searchText"
      />
      {!value && (
        <input
          className={'btn user-search-icon'}
          type="submit"
          value="Search"
        />
      )}
      {value && <span className="icon-cancel-white" onClick={clear} />}
    </div>
  </div>
);

export const ClearButton = ({ clear }) => (
  <div className="apply-filter-btn">
    <button
      type="button"
      className="btn btn-link btn-clear"
      onClick={() => clear()}
    >
      CLEAR ALL
    </button>
  </div>
);

export const FilterOptions = ({
  type,
  State,
  region,
  handleType,
  handleState,
  handleRegion,
  clear,
  typeData,
  stateData,
  regionData
}) => (
    <div className="breadcrumbs d-flex justify-content-between filter-key">
      <div className="select-filter-key">
        <div className="select-type select-box--custom">
          <SelectField
            value={type}
            placeholder="Select Type"
            name="type"
            options={typeData}
            onChange={handleType}
          />
        </div>
        <div className="select-state select-box--custom">
          <SelectField
            value={State}
            placeholder="Select State"
            name="state"
            options={stateData}
            onChange={handleState}
          />
        </div>
        <div className="select-region select-box--custom">
          <SelectField
            value={region}
            placeholder="Select Region"
            name="region"
            options={regionData}
            onChange={handleRegion}
          />
        </div>
        <ClearButton clear={clear} />
      </div>
    </div>
  );

export const ActionButton = ({ action, text }) => (
  <div className="subheader-btn">
    <button
      type="button"
      className="btn alternative-btn"
      onClick={() => action()}
    >
      <span className="btn-text">{text}</span>
    </button>
  </div>
);

export const Filters = ({ isOn, toggle }) => (
  <div className="subheader-btn">
    <button
      type="button"
      className={
        isOn
          ? 'btn alternative-btn btn-hide filter-btn'
          : 'btn alternative-btn filter-btn'
      }
      onClick={() => toggle()}
    >
      <span
        className={`btn-icon ${
          isOn ? 'icon-cancel-white' : 'icon-filter-icon'
          }`}
      />
      <span className="btn-text">{isOn ? 'Hide' : 'Filter'}</span>
    </button>
  </div>
);

export const ActionFilter = ({ onchange, value }) => (
  <div className=" user-search search-form">
    <div className="form-group select-box--custom">
      <SelectField
        value={value}
        placeholder="Select Severity"
        options={SeverityOptions}
        onChange={onchange}
      />
    </div>
  </div>
);

function SubNav(props) {
  const { children } = props;
  return (
    <>
      <div className="navbar navbar-default fixed-bottom navbar-expand navigation-bar">
        <NavItems wrapperClass="navbar-nav" itemClass="nav-item" />
      </div>
      <section className="subheader-container d-flex justify-content-between align-items-center">
        <div className="container">{children}</div>
      </section>
    </>
  );
}
const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  { toggleSorting }
)(SubNav);
