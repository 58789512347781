import { getMySitesApi, updateMySitesApi } from "../xhrCalls";
import { beginAjaxCall, endAjaxCall } from './xhrSatus'
import { MYSITES } from "./actionType";

/**
 * Action for get mysite list
 */
export const getMySites = (userId, clientId, pageNumber, loader) => dispatch => {
  loader && dispatch(beginAjaxCall())
  return getMySitesApi(userId, clientId, pageNumber)
    .then(response => {
      dispatch(endAjaxCall())
      dispatch({
        type: MYSITES,
        payload: response.data.data
      });
      return response;
    })
    .catch(error => {
      dispatch(endAjaxCall())
      console.error(error);
      return error;
    });
}

/**
 * Action for updating mysite list
 */
export const updateMySites = (payload, userId, clientId) => dispatch =>
  updateMySitesApi(payload, userId, clientId)
    .then(response => response)
    .catch(error => {
      console.error(error);
      return error;
    });

export const setSiteCards = cards => dispatch =>
  dispatch({
    type: MYSITES,
    payload: cards
  });
