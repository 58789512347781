import axios from 'axios';
import { APPLICATIONS_LIST, UPDATE_APPLICATION_CARDS } from '../../constants';

/**
 * Function to get intelligence cards
 */
export const getAppsApi = (userId, clientId) =>
  axios.get(`${APPLICATIONS_LIST}?user_id=${userId}&client=${clientId}`);

export const updateAppsApi = (payload, userId, clientId) =>
  axios.post(
    `${UPDATE_APPLICATION_CARDS}?client=${clientId}&user_id=${userId}`,
    payload
  );
