import React from 'react';
import { ModalView } from '../layout/ModalView';

function createMarkup(html) {
    return { __html: html };
}

export default function IframeModal(props) {
    const {
        isOpen,
        toggleModel,
        url,
        iframeClass,
        iframeModal
    } = props;
    const iframe = url && `<iframe src=${url} />`;
    return (
        <ModalView
            modelClass="full-screen-model"
            toggle={toggleModel}
            bodyClass="full-screen-model-body"
            isOpen={isOpen}
            iframeClass={iframeClass}
            iframeModal={iframeModal}
        >
            <div className="grid-view">
                <div className="row">
                    <div className="card-view full-screen-card">
                        <div className="card">
                            <div className="row-1 large-card-with-energy-data">
                                <div
                                    className="energy-section"
                                    dangerouslySetInnerHTML={createMarkup(iframe)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalView>
    );
}
