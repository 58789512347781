import React from 'react';

export default function ProfileSetting({ logout, toggleModel }) {
  return (
    <ul className="list-unstyled profile-setting">
      <li>
        <a
          className="btn-with-icon "
          href
          onClick={() => toggleModel('showModule', true)}
        >
          <span className="btn-icon icon modules-icon">Icon</span>
          <span className="btn-text">Modules</span>
        </a>
      </li>
      <li>
        <a
          className="btn-with-icon "
          href
          onClick={() => toggleModel('showChangePassword', true)}
        >
          <span className="btn-icon icon change-password-icon">Icon</span>
          <span className="btn-text">Change Password</span>
        </a>
      </li>
      <li>
        <a className="btn-with-icon logout" onClick={() => logout()} href>
          <span className="btn-icon icon logout-icon">Icon</span>
          <span className="btn-text">Logout</span>
        </a>
      </li>
    </ul>
  );
}
