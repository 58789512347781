import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const ModalView = props => {
  const { isOpen, toggle, children, modelClass, bodyClass, style, isShare, heading, footer, titleClass, iframeClass, iframeModal } = props;
  const iframeBodyClass = iframeClass ? iframeClass : '';
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggle(!isOpen)}
      className={`${iframeModal} ${modelClass}`}
      style={style}
    >
      <ModalHeader toggle={() => toggle(!isOpen)} className={titleClass}>{heading}</ModalHeader>
      <ModalBody className={`${bodyClass} ${iframeBodyClass}`}>{children}</ModalBody>
      {isShare && <ModalFooter>{footer}</ModalFooter>}
    </Modal>
  );
};
