import { SHOW_SHARE_MODAL, SHARE_USERS } from '../actions';

/**
 * Function for toggle share modal
 * @param  {Object} state
 * @param  {Object} action
 */
const initialState = {};
export const toggleShareModel = (state, action) => {
  const { type, payload } = action;
  if (typeof state === 'undefined') {
    return initialState;
  } else if (type === SHOW_SHARE_MODAL) {
    return { ...state, ...payload };
  } else {
    return state;
  }
};

const initialValue = [];
const userId = sessionStorage.getItem('userId');
export const shareUsers = (state, action) => {
  const { type, payload } = action;
  if (typeof state === 'undefined') {
    return initialValue;
  } else if (type === SHARE_USERS) {
    const list = [];
    payload.map(user => {
      if (user.has_access && user.userid !== parseInt(userId)) {
        const obj = {
          value: user.userid,
          label: `"${user.firstname}"  <${user.email}>`,
          firstname: user.firstname,
          email: user.email
        };
        return list.push(obj);
      } else {
        return false;
      }
    });
    return [...list];
  } else {
    return state;
  }
};
