import React, { useState } from 'react';

import TablueReport from './TablueReport';
import { CardMedia } from './CardsMedia';
import { Analytics } from './Analytics';
import CardInfo from './CardInfo';

export default function LargeCard(props) {
  const { card, carddetails, cardLoading, handleBookmarkArray,
    bookmarkArray } = props;
  const isId =
    card.facilitysk || card.content_id || card.platformcontentsk || card.id;
  const hasAnyBookmark = bookmarkArray.includes(isId);
  const [isBookmark, setToggleBookmark] = useState(hasAnyBookmark);

  function handleToggleValue(value) {
    setToggleBookmark(value);
    handleBookmarkArray(value, isId);
  }
  return (
    <div
      className={
        cardLoading.cardLoader &&
          (cardLoading.cardLoader === card.share_card_id ||
            (cardLoading.cardLoader === isId && !card.share_card_id))
          ? 'card-animation card'
          : 'card'
      }
    >
      <div className="row large-card-with-energy-data">
        {!card.content_id &&
          <div className="col-lg-6">
            <div className="row mr-0 ml-0 medium-card-media-row">
              <div className="col-md-4 col-lg-6 pl-0 pr-0">
                <CardMedia
                  {...props}
                  isBookamrk={isBookmark}
                  handleToggle={handleToggleValue}
                />
              </div>
              <div className="col-md-8 col-lg-6 pl-0 pr-0">
                <div className="card-body pt-1">
                  <h5 className="card-title">{card.title}</h5>
                  <p className="card-text">
                    {`${card.address1 || ''} ${card.city || ''} ${card.region || ''}`}
                  </p>
                </div>
                {card.facilitysk && <div className="divider" />}
                <CardInfo {...props} />
              </div>
            </div>
            <Analytics analytics={carddetails} {...props} />
          </div>}
        <TablueReport
          analytics={carddetails}
          className={card.content_id ? 'col-lg-12 energy-block' : 'col-lg-6 energy-block'}
          {...props}
          isBookamrk={isBookmark}
          handleToggle={handleToggleValue}
        />
      </div>
    </div>
  );
}
