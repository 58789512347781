import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ModalView } from '../layout/ModalView';
import {
  getUserModules,
  toggleModuleStatus,
  getIntelligence,
  getHomeCards
} from '../../redux/actions';

class Module extends Component {
  constructor(props) {
    super(props);
    this.state = { modules: [] };
    this.userId = sessionStorage.getItem('userId');
    this.clientCode = sessionStorage.getItem('clientCode');
  }

  componentDidMount() {
    const { getUserModules, userId, clientCode } = this.props;
    this.userId = this.userId || userId;
    this.clientCode = this.clientCode || clientCode;
    if (this.userId) {
      getUserModules(this.userId).then(res => {
        if (res.data) {
          this.setState({ modules: res.data.data });
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { userId, getUserModules, clientCode } = this.props;
    if (prevProps.userId !== userId) {
      this.userId = userId || this.userId;
      this.clientCode = this.clientCode || clientCode;
      getUserModules(this.userId).then(res => {
        this.setState({ modules: res.data ? res.data.data : [] });
      });
    }
  }

  handleChange = async (event, index) => {
    const { modules } = this.state;
    const { toggleModuleStatus, getIntelligence, getHomeCards } = this.props;
    this.clientCode = sessionStorage.getItem('clientCode');
    const { checked } = event.target;
    const temp = [...modules];
    const payload = { user_id: this.userId, ...temp[index] };
    temp[index].status = checked;
    this.setState({ modules: temp });
    await toggleModuleStatus(payload);
    getIntelligence(this.userId, this.clientCode);
    getHomeCards(this.userId, this.clientCode);
  };

  render() {
    const { isOpen, toggleModel } = this.props;
    const { modules } = this.state;
    return (
      <ModalView
        modelClass="modal-dialog-centered modal-md-custom"
        toggle={() => toggleModel('showModule', false)}
        bodyClass="modal-md-custom-body"
        isOpen={isOpen}
        heading="Modules"
      >
        <div className="form-group mb-0 mt-32">
          <ul className="list-unstyled module-container">
            {modules && modules.map((item, i) => (
              <li key={item.module}>
                <div className="move-position">
                  <span className="move-position-text">
                    {item.moduledisplaylabel}
                  </span>

                  {/* * ToggleModule is move to second phase */}
                  <label className="switch d-none">
                    <input
                      type="checkbox"
                      checked={item.status}
                      onChange={e => this.handleChange(e, i)}
                    />
                    <span className="slider round" />
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </ModalView>
    );
  }
}
const mapStateToProps = state => ({
  userId: state.userId,
  clientCode: state.clientCode
});
export default connect(
  mapStateToProps,
  { getUserModules, toggleModuleStatus, getIntelligence, getHomeCards }
)(Module);
