import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

export default function ReactDropdown({ label, options, onSelect }) {
  const [dropdownOpen, toggle] = useState(false);
  return (
    <Dropdown isOpen={dropdownOpen} toggle={() => toggle(!dropdownOpen)}>
      <DropdownToggle>
        <span className="">{label}</span>
        <span className="icon-down-arrow-white" />
      </DropdownToggle>
      <DropdownMenu
        className="custom-ui"
        modifiers={{
          setMaxHeight: {
            enabled: true,
            fn: data => ({
              ...data,
              styles: {
                overflow: "auto",
                maxHeight: 600
              }
            })
          }
        }}
      >
        {Object.keys(options) &&
          Object.keys(options).map((elememt, index) => (
            <>
              <DropdownItem header key={index} style={{ color: "Black" }}>{elememt}</DropdownItem>
              {options[elememt].map(elememt => (
                <DropdownItem
                  style={{ color: label === elememt.clientname ? "black" : '', backgroundColor: label === elememt.clientname ? "#faf8f5" : '' }}
                  key={elememt.clientcode}
                  onClick={() =>
                    onSelect(elememt.clientcode, elememt.clientname, elememt.image)}
                >
                  {elememt.clientname}
                </DropdownItem>
              ))}
            </>
          ))}
      </DropdownMenu>
    </Dropdown>
  );
}
