import { USER_CLIENT, CLIENT_CODE } from './actionType';

export const logOut = () => dispatch => {
  sessionStorage.clear();
  dispatch({
    type: USER_CLIENT,
    payload: ''
  });
  dispatch({
    type: CLIENT_CODE,
    payload: {
      clientCode: '',
      clientLogo: '',
      clientName: ''
    }
  });
};
