import { USER_CLIENT, CLIENT_CODE } from '../actions/actionType';
import { groupBy } from '../../utils'

/**
 * Function for user client
 * @param  {Object} state
 * @param  {Object} action
 */
const initialState = {};
export const client = (state, action) => {
  const { type, payload } = action;
  if (typeof state === 'undefined') {
    return initialState;
  } else if (type === USER_CLIENT) {
    const groupByPatron = groupBy('patronclientname');
    const clients = groupByPatron(payload);
    return { ...initialState, ...clients };
  } else {
    return state;
  }
};

/**
 * Function for  client
 * @param  {Object} state
 * @param  {Object} action
 */
const initialValue = { clientName: 'Select Client', clientCode: '' };
export const clientInfo = (state, action) => {
  const { type, payload } = action;
  if (typeof state === 'undefined') {
    return initialValue;
  } else if (type === CLIENT_CODE) {
    return payload;
  } else {
    return state;
  }
};
