import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { IMAGE_ERROR, CLOSE_ICON_IMAGE } from "../../utils";
import { DEFAULT_USER_IMAGE_NAV } from '../../constants';
import { toast } from "../../redux/actions";
import { ScreenLoader } from '../layout/ScreenLoader';

function UploadImage(props) {
  const { image, onUploadImage, onRemoveImage, toast, loading, disabled, removeClass } = props;
  const [isDefaultImage, setDefaultImage] = useState(false);

  function checkDefaultImage() {
    const pathname = new URL(image).pathname;
    if (pathname === '/user/default_profile_image.jpg') {
      setDefaultImage(true);
    } else {
      setDefaultImage(false);
    }
  }

  useEffect(() => {
    checkDefaultImage();
  });

  function selectImage(event) {
    const file = event.target.files[0];
    if (
      file.type.includes('jpeg') ||
      file.type.includes('jpg') ||
      file.type.includes('png')
    ) {
      const reader = new FileReader();
      reader.onload = (event) => {
        onUploadImage(event);
      };
      reader.readAsDataURL(file);
    } else {
      return toast(IMAGE_ERROR, true, true);
    }
    return false;
  }

  function onRemove(event) {
    onRemoveImage(event);
  }

  function onError(event) {
    event.target.src = image;
  }

  return (
    <div className="col-sm-12 mt-32 upload-image">
      <div className="upload-image-container d-flex align-items-end">
        <div className="image-box d-flex align-items-center justify-content-center">
          {!isDefaultImage && (
            <img
              className={`close-icon ${removeClass}`}
              src={CLOSE_ICON_IMAGE}
              alt="Remove Icon"
              onClick={(event) => onRemove(event)}
            />
          )}
          <img
            className="profile-picture"
            src={image || DEFAULT_USER_IMAGE_NAV}
            height={image ? "75" : "24"}
            width={image ? "75" : "24"}
            alt="Default Icon"
            onError={(event) => onError(event)}
          />
          <div className="image-loader">{loading && <ScreenLoader />}</div>
        </div>
        <div className="upload-btn-box">
          <div className="custom-file">
            <input
              className="custom-file-input"
              type="file"
              accept="image/*"
              id="image"
              required="required"
              disabled={disabled}
              onChange={(event) => {
                selectImage(event);
              }}
            />
            <label
              className="custom-file-label btn upload-btn"
              htmlFor="customFileLang"
            >
              {!isDefaultImage ? "Change Image" : "Upload Image"}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { toast })(UploadImage);
