import React, { Component } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { connect } from 'react-redux';

import {
  toggleSiteModel,
  toggleShareModel,
  toggleAddNotesModel
} from '../../redux/actions';
import { getCardType, EDIT_NOTES, ADD_NOTES, APP } from '../../utils';

class CardActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      bookmark: false
    };
  }

  componentDidMount() {
    const { siteDetail, isActionDetail, actionDetail } = this.props;
    this.setState({
      bookmark: isActionDetail
        ? actionDetail.bookmark
        : siteDetail[0].data1[0].bookmark
    });
  }

  handleBookMarkIcon() {
    const { handleBookmark, card, toggleSiteModel, isOpen, isHome } = this.props;
    const { bookmark } = this.state;
    this.setState({ bookmark: !bookmark });
    handleBookmark(card, bookmark);
    setTimeout(() => {
      isHome && toggleSiteModel(!isOpen.isShow);
    }, 500);
  }

  getNoteStatus = (id) => {
    const { notes_info, card } = this.props;
    if (notes_info && notes_info.content_id === id && notes_info.note_id) {
      return notes_info.note_id;
    } else {
      if (notes_info.key !== 'deleted' && card.note_id) {
        return card.note_id;
      } else {
        return false;
      }
    }
  }

  render() {
    const { className, card, toggleShareModel, toggleAddNotesModel, handleDelete, userId, clientCode, share_card_id } = this.props;
    const { dropdownOpen, bookmark } = this.state;

    const idForShare = card.facilitysk || card.content_id || card.platformcontentsk || card.id;
    const cardType = getCardType(card);
    const user = userId || sessionStorage.getItem('userId');
    const client = clientCode || sessionStorage.getItem('clientCode');
    const noteId = this.getNoteStatus(idForShare);
    return (
      <span className={className}>
        {!card.is_shared && <a
          href
          className={`icon-heart-dark ${(card.card_type || bookmark) &&
            'active'}`}
          onClick={() => this.handleBookMarkIcon()}
        >
          Add to favorites
        </a>}
        <span className="dropdown-custom">
          <Dropdown
            isOpen={dropdownOpen}
            toggle={() => this.setState({ dropdownOpen: !dropdownOpen })}
            direction="left"
          >
            <DropdownToggle className="link">
              <span className="icon-dot-icon" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu custom-ui">

              {/* * Add Note is Move to second phase */}
              {cardType !== APP && !card.is_shared &&
                <DropdownItem
                  className='d-none'
                  onClick={() => toggleAddNotesModel(true, idForShare, cardType, noteId, user, client)}> {noteId ? EDIT_NOTES : ADD_NOTES}
                </DropdownItem>}

              <DropdownItem onClick={() => toggleShareModel(true, idForShare, cardType, user, client)}>Share</DropdownItem>
              {!!card.is_shared && (
                <DropdownItem onClick={() => handleDelete(share_card_id, idForShare)} className="delete-btn">Delete Card</DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </span>
      </span>
    );
  }
}

const mapStateToProps = state => ({
  isOpen: state.toggleSiteModel,
  siteDetail: state.siteDetail,
  actionDetail: state.actionDetail,
  clientCode: state.clientInfo.clientCode,
  userId: state.userId,
  notes_info: state.notes_info
});

export default connect(
  mapStateToProps,
  {
    toggleSiteModel,
    toggleShareModel,
    toggleAddNotesModel
  }
)(CardActions);
