import { changePassword } from '../xhrCalls';
import { toast } from './toast';
import { CHANGED_PASSWORD } from '../../utils';

/**
 * Action for Change password
 */
export const UpdatePassword = payload => dispatch =>
  changePassword(payload)
    .then(response => {
      dispatch(toast(CHANGED_PASSWORD, true));
      return response;
    })
    .catch(error => {
      if (error.response) {
        dispatch(
          toast(error.response.data.errorCauses[0].errorSummary, true, true)
        );
      }
      return error;
    });
