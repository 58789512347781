import React from "react";

export default function SortableToggleButton(getOs, toggle, isOn) {
    return getOs() && (
    <div className="move-position">
      <span className="move-position-text">Move Position</span>
      <label className="switch">
        <input
          type="checkbox"
          onChange={() => toggle(!isOn)}
          checked={isOn}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
}
