import React, { Component } from 'react';
import axios from 'axios';
import { withAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import RenderCard from '../../cards/renderCard';
import FullViewCard from '../../cards/FullViewCard';
import AddNotes from '../../layout/AddNotes';
import { GET_MYSITE_FILTERS } from '../../../constants';

import {
  getMySites,
  toggleSiteModel,
  updateMySites,
  getSite,
  toggleBookmark,
  setSiteCards,
  toast,
  toggleAddNotesModel,
  siteDetail
} from '../../../redux/actions';
import {
  APP,
  ACTION,
  updateSizePayload,
  SMALL,
  WELCOME_SITE_TEXT,
  searchInArray,
  filterSite,
  NO_CARD,
  UNBOOKMARKED_TEXT,
  BOOKMARKED_TEXT
} from '../../../utils';
import WelcomeSiteCard from '../../cards/WelcomeCard';
import CardNotFound from '../../cards/CardNotFound';
import { ScreenLoader } from '../../layout/ScreenLoader';
import SubNav, {
  FilterOptions,
  SearchForm,
  Filters
} from '../../layout/SubNav';

class MySite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sitedetails: { searchText: '' },
      pageNumber: 1,
      bookmarkArray: [],
      typeData: [],
      stateData: [],
      regionData: []
    };
    this.userId = sessionStorage.getItem('userId');
    this.clientCode = sessionStorage.getItem('clientCode');
  }

  componentDidMount() {
    const { clientCode, userId } = this.props;
    this.userId = this.userId || userId;
    this.clientCode = this.clientCode || clientCode;
    if (this.clientCode) {
      window.addEventListener('scroll', this.handleOnScroll);
      this.getSitesList(this.userId, this.clientCode, 1, false);
    }
  }

  componentDidUpdate(prevState) {
    const { clientCode, userId } = this.props;
    if (clientCode && prevState.clientCode !== clientCode) {
      this.userId = userId || this.userId;
      this.clientCode = clientCode;
      this.setState({
        searchText: '',
        type: null,
        state: null,
        region: null,
        isFilter: false
      });
      window.addEventListener('scroll', this.handleOnScroll);
      this.getSitesList(this.userId, this.clientCode, 1);
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleOnScroll);
  }

  getSitesList = (userId, clientId, pageNumber, loader = true,) => {
    const { getMySites, setSiteCards } = this.props;
    pageNumber = pageNumber || this.state.pageNumber;
    getMySites(userId, clientId, pageNumber, loader).then(async resp => {
      if (resp && resp.data) {
        const { sites } = this.state;
        const mySiteList = pageNumber === 1 ? resp.data.data : [...sites, ...resp.data.data];
        setSiteCards(mySiteList);
        this.setState({ sites: mySiteList, isSearching: false, prevPage: pageNumber, pageNumber: resp.data.next_page, loadMore: false });
      } else {
        this.setState({ sites: [], loadMore: false, prevPage: pageNumber });
      }
    });
  };

  prepareDetails = async (list, getSite) => {
    const { bookmarkArray } = this.state;
    const arr = {};
    list.map(async item => {
      new Promise(resolve => {
        if (item.bookmark && !bookmarkArray.includes(item.facilitysk)) {
          bookmarkArray.push(item.facilitysk);
        }
        if (item.size.trim() !== SMALL) {
          getSite(item.facilitysk, this.userId, this.clientCode, 0).then(
            res => {
              arr[item.facilitysk] = res[0];
              resolve(arr);
              this.setState({ sitedetails: {} });
            }
          );
        } else {
          this.setState({ sitedetails: {} });
        }
      }).then(() => {
        this.setState(() => ({ sitedetails: arr }));
      });
    });
  };

  pushdetails = id => {
    const { getSite } = this.props;
    const { sitedetails } = this.state;
    const arr = { ...sitedetails };
    getSite(id, this.userId, this.clientCode, 0).then(res => {
      arr[id] = res[0];
      this.setState({ sitedetails: arr });
    });
  };

  updateCardSize = (index, size) => {
    const { sites, sitedetails } = this.state;
    const { setSiteCards, mySiteList } = this.props;
    const tempSites = [...sites];
    const tempObj = { ...sites[index] };
    tempObj.size = size;
    tempSites[index] = tempObj;

    const index1 = mySiteList.findIndex(k => k.facilitysk === tempObj.facilitysk);
    const tempSites1 = [...mySiteList];
    const tempObj1 = { ...mySiteList[index1] };
    tempObj1.size = size;
    tempSites1[index1] = tempObj1;

    this.setState({ sites: tempSites });
    setSiteCards(tempSites1);

    if (size === SMALL && sitedetails[tempObj.facilitysk]) {
      delete sitedetails[tempObj.facilitysk];
    }
    const payload = updateSizePayload(tempObj, this.userId, this.clientCode, index);
    this.updateCard(payload);
    this.pushdetails(tempObj.facilitysk);
  };

  handleBookmark = async (card, bookmarkValue) => {
    const { sites } = this.state;
    const { toggleBookmark, setSiteCards, mySiteList, toast } = this.props;
    const temp = [...sites];
    const params = {
      user_id: this.userId,
      content_id: card.facilitysk,
      client: this.clientCode,
      card_type: 'SITE',
      bookmarked: bookmarkValue
    };
    // for list
    const index = temp.findIndex(k => k.facilitysk === card.facilitysk);
    const tempobj = { ...temp[index] };
    tempobj.bookmark = !card.bookmark;
    temp[index] = tempobj;
    const tempProps = [...mySiteList];
    const tempIndex = tempProps.findIndex(
      k => k.facilitysk === card.facilitysk
    );
    tempProps[tempIndex] = tempobj;
    setSiteCards(tempProps);
    temp[index] = tempobj;

    this.handleBookmarkArray(!bookmarkValue, card.facilitysk);
    this.setState({ sites: temp });
    await toggleBookmark(params);
    if (card.bookmark) {
      toast(UNBOOKMARKED_TEXT, true);
    } else {
      toast(BOOKMARKED_TEXT, true);
    }
  };

  handleChange = event => {
    const { value, name } = event.target;
    const { state, region, type } = this.state;
    const str = value.replace(/^\s+/g, '');
    const { mySiteList } = this.props;
    const result = searchInArray(mySiteList, str);
    let records = type ? filterSite(result, type.value) : result;
    records = state ? filterSite(result, state.value) : records;
    records =
      region && region.value !== 'all'
        ? filterSite(result, region.value)
        : records;
    let isSearching = false;
    if (records.length < 1) {
      isSearching = true;
    }
    this.setState({
      sites: records,
      [name]: str,
      isSearching: isSearching
    });
  };

  clearSearch = () => {
    const { mySiteList } = this.props;
    this.setState({
      sites: mySiteList,
      isSearching: false,
      searchText: ''
    });
  };

  toggleFilter = () => {
    const { isFilter } = this.state;
    if (isFilter) {
      const { mySiteList } = this.props;
      this.setState({
        sites: mySiteList,
        isSearching: false,
        type: null,
        state: null,
        region: null,
        searchText: '',
        typeData: [],
        stateData: [],
        regionData: []
      });
    } else {
      axios.get(`${GET_MYSITE_FILTERS}?user_id=${this.userId}&client=${this.clientCode}`)
        .then(res => {
          this.setState({
            typeData: res.data.data[0].type,
            stateData: res.data.data[0].state,
            regionData: res.data.data[0].region,
          });
        })
        .catch(error => {
          throw error;
        });
    }
    this.setState(prevState => ({ isFilter: !prevState.isFilter }));
  }

  applyFilter = (type, state, region) => {
    const { mySiteList } = this.props;
    const { searchText } = this.state;
    const records = searchText
      ? searchInArray(mySiteList, searchText)
      : mySiteList;
    let result = type ? filterSite(records, type) : records;
    result = state ? filterSite(result, state) : result;
    result =
      region && region.value !== 'all'
        ? filterSite(result, region)
        : result;
    let isSearching = false;
    if (result.length < 1) {
      isSearching = true;
    }
    this.setState({
      sites: result,
      isSearching: isSearching
    });
  };

  clearFilters = () => {
    const { mySiteList } = this.props;
    const { searchText } = this.state;
    const result = searchText
      ? searchInArray(mySiteList, searchText)
      : mySiteList;
    this.setState({
      sites: result,
      isSearching: false,
      type: null,
      state: null,
      region: null
    });
  };

  handleNote = (cardId, hasNote) => {
    const { sites } = this.state;
    const temp = [...sites];
    const index = temp.findIndex(k => k.facilitysk === cardId);
    const tempobj = { ...temp[index] };
    tempobj.note_id = hasNote;
    temp[index] = tempobj;
    this.setState({ sites: temp });
  };

  getObject = (cards, id) =>
    cards.find(
      obj => obj.content_id === id || obj.facilitysk === id || obj.id === id
    );

  handleType = type => {
    const { state, region } = this.state;
    this.applyFilter(type, state, region);
    this.setState({ type });
  };

  handleState = state => {
    const { type, region } = this.state;
    this.applyFilter(type, state, region);
    this.setState({ state });
  };

  handleRegion = region => {
    const { type, state } = this.state;
    this.applyFilter(type, state, region);
    this.setState({ region });
  };

  updateList = (isFilterOn) => {
    const { clientCode, userId } = this.props;
    const { searchText, isFilter, pageNumber } = this.state;
    this.userId = userId || this.userId;
    this.clientCode = clientCode || this.clientCode;
    if (isFilterOn || ((searchText === '' || searchText === undefined) && !isFilter)) {
      this.getSitesList(this.userId, this.clientCode, pageNumber, false);
    }
  }

  handleDoubleClick = async (card, cardSize) => {
    let size;
    if (cardSize === SMALL) {
      size = SMALL;
    }
    if (size !== SMALL) {
      return false;
    }
    const {
      toggleSiteModel,
      isOpen,
      siteDetail
    } = this.props;
    const user = this.userId;
    const client = this.clientCode;
    await siteDetail(card.facilitysk, user, client, card.is_shared);
    toggleSiteModel(!isOpen.isShow, card.share_card_id, false, card.from_user_email);
    return true;
  };

  navBar = () => {
    const {
      searchText,
      isFilter,
      type,
      state,
      region,
      typeData,
      stateData,
      regionData
    } = this.state;
    return (
      <SubNav>
        <div className="breadcrumbs d-flex justify-content-between">
          <h2 className="title pr-2">MySites</h2>
          <div className="d-flex justify-content-end align-items-center breadcrumbs-action ">
            <SearchForm
              onChange={this.handleChange}
              value={searchText}
              clear={this.clearSearch}
            />
            <Filters toggle={this.toggleFilter} isOn={isFilter} />
          </div>
        </div>
        {isFilter && (
          <FilterOptions
            type={type}
            State={state}
            region={region}
            handleType={this.handleType}
            handleState={this.handleState}
            handleRegion={this.handleRegion}
            clear={this.clearFilters}
            typeData={typeData}
            stateData={stateData}
            regionData={regionData}
          />
        )}
      </SubNav>
    );
  };

  card = isSearching =>
    (isSearching ? (
      <CardNotFound text={NO_CARD} />
    ) : (
      <WelcomeSiteCard text={WELCOME_SITE_TEXT} />
      ));

  updateCard(payload) {
    const { updateMySites } = this.props;
    updateMySites(payload, this.userId, this.clientCode);
  }

  handleBookmarkArray = (bookmarkValue, id) => {
    const { bookmarkArray } = this.state;
    if (bookmarkValue && !bookmarkArray.includes(id)) {
      bookmarkArray.push(id);
    } else if (!bookmarkValue && bookmarkArray.includes(id)) {
      const index = bookmarkArray.findIndex(element => element === id);
      delete bookmarkArray[index];
    }
  }

  handleOnScroll = () => {
    // http://stackoverflow.com/questions/9439725/javascript-how-to-detect-if-browser-window-is-scrolled-to-bottom
    const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || window.innerHeight;
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    if (scrolledToBottom) {
      const { prevPage, pageNumber } = this.state;
      if (prevPage < pageNumber) {
        this.setState({ loadMore: true });
        this.updateList();
      }
    }
  }

  render() {
    const {
      isOpen: { isShow },
      toggleSiteModel,
      loading: { loading },
    } = this.props;
    const { sites,
      sitedetails,
      isSearching,
      searchText,
      bookmarkArray, isFilter, loadMore } = this.state;
    if (!sites) {
      return (
        <>
          {this.navBar()}
          <ScreenLoader />
        </>
      );
    }
    return (
      <>
        {this.navBar()}
        {loading && (
          <div className="loader-full-page">
            <ScreenLoader />
          </div>
        )}
        <FullViewCard
          isOpen={isShow}
          toggleModel={toggleSiteModel}
          handleBookmark={this.handleBookmark}
        />
        {sites.length < 1 ? (
          this.card(isSearching)
        ) : (
          <div className="container">
            <div
              className="grid-view"
              id="CustomHelperContainer"
              ref={this.setContainerNode}
              >
              <ul className="row">
                {this.props.isAddNote.isAddNoteOpen && (
                <AddNotes
                  isOpen={this.props.isAddNote.isAddNoteOpen}
                  toggleModel={this.props.toggleAddNotesModel}
                  handleNote={this.handleNote}
                  {...this.props}
                    />
                  )}
                {sites.map((item, index) => {
                    const id = item.facilitysk || item.content_id;
                    const obj = sitedetails && sitedetails[id];
                    return (
                      <RenderCard
                        key={Math.random()}
                        index={index}
                        ind={index}
                        card={item}
                        carddetails={obj}
                        isEnable={item.card_type === APP}
                        isActionDetail={item.card_type === ACTION}
                        isShow={isShow}
                        toggleSiteModel={toggleSiteModel}
                        updateSize={this.updateCardSize}
                        cardDetail={sitedetails}
                        handleNote={this.handleNote}
                        handleDoubleClick={this.handleDoubleClick}
                        bookmarkArray={bookmarkArray}
                        handleBookmarkArray={this.handleBookmarkArray}
                        {...this.props}
                      />
                    );
                  })}
              </ul>
              {loadMore && (searchText === '' || searchText === undefined) && !isFilter && <ScreenLoader />}
            </div>
          </div>
          )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  mySiteList: state.mySites,
  isOpen: state.toggleSiteModel,
  loading: state.xhrStatus,
  userId: state.userId,
  clientCode: state.clientInfo.clientCode,
  hasNote: state.toggleAddNotesModel.hasNote,
  cardLoading: state.cardAjaxStatus,
  isAddNote: state.toggleAddNotesModel,
});

export default withAuth(
  connect(
    mapStateToProps,
    {
      getMySites,
      toggleSiteModel,
      updateMySites,
      getSite,
      toggleBookmark,
      setSiteCards,
      toast,
      toggleAddNotesModel,
      siteDetail
    }
  )(MySite)
);
