import config from '../config';

export const BASE_URL = config.OKTA_BASE_URL;
export const CLIENT_ID = config.OKTA_CLIENT_ID;
export const GOOGLE_MAP_KEY = 'AIzaSyC4PQELkbWQ1kWdYF_dPtSrBl6o2WN6PeQ';
export const INSITE_LOGO = '/assets/images/insite_logo.png';
export const OKTA_TOKEN = 'okta-token-storage';
export const EMAIL = 'email=';
export const SUB = '&sub=';
export const AUDIENCE = '&audience=';
export const ISSUED = '&issued=';
export const ISSUER = '&issuer=';
export const USERID = 'userid';
export const USER_ID = localStorage.getItem(USERID);
export const CLIENT = localStorage.getItem('client');
export const SMALL = 'S';
export const MEDIUM = 'M';
export const LARGE = 'L';
export const WELCOME_HOME_TEXT =
  'Favorite an Action, Intelligence, MySites or Application Card to get started';
export const WELCOME_SITE_TEXT = 'No available MySites yet!';
export const WELCOME_INTELLIGENCE_TEXT = 'No available Intelligence yet!';
export const WELCOME_APPS_TEXT = 'No available Applications yet!';
export const WELCOME_ACTIONS_TEXT = 'Seems no available actions yet!';
export const NO_CARD = 'No result found';
export const CARDS_PER_PAGE = 24;
export const INTEL = 'INTEL';
export const SITE = 'SITE';
export const MYSITES = 'MySites';
export const INTELLIGENCE = 'Intelligence';
export const APPS = 'Applications';
export const APP = 'APP';
export const ACTION = 'ACTION';
export const ACTIONS = 'Actions';
export const ROUTES = [
  '/home',
  '/mysites',
  '/applications',
  '/intelligence',
  '/actions',
  '/signIn',
  '/implicit/callback',
  '/'
];
export const SITE_TYPE = [
  { value: 'Mixed Use Property', label: 'Mixed Use Property' },
  { value: 'Multifamily Housing', label: 'Multifamily Housing' },
  { value: 'Office', label: 'Office' },
  { value: 'Other', label: 'Other' }
];
export const REGIONS = [
  { value: 'all', label: 'All' },
  { value: 'Central', label: 'Central' },
  { value: 'East', label: 'East' },
  { value: 'South', label: 'South' },
  { value: 'West', label: 'West' }
];
export const ADD_NOTE_TEXT = 'Note has been added successfully.';
export const SHARED_TEXT = 'Card has been shared successfully.';
export const MY_PROFILE_TEXT = ' Profile has been updated successfully.';
export const MY_PROFILE_PIC_TEXT =
  ' Profile image has been updated successfully.';
export const MY_PROFILE_PIC_TEXT_REMOVED =
  ' Profile image has been removed successfully.';
export const DELETED_TEXT = 'Note has been deleted successfully.';
export const SHARED_DELETED = 'Card has been deleted successfully.';
export const CHANGED_PASSWORD = 'Your password successfully changed.';
export const IMAGE_ERROR =
  'Please select correct image format (JPEG ,JPG & PNG).';
export const EDIT_NOTES = 'Edit Note';
export const ADD_NOTES = 'Add Note';
export const DEFAULT_USER_IMAGE = 'assets/images/image-icon.svg';
export const CLOSE_ICON_IMAGE = 'assets/images/close-icon.png';
export const DEFAULT_USER_IMAGE_NAV = 'assets/images/default-profile-pic.svg';
export const REQUIRED = 'This field is required.';
export const passwordRejex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{4,}$/;
export const ValidatePassword = password => passwordRejex.test(password);
export const INVALID_PASSWORD =
  'Password should be minimum 4 characters and must contain at least 1 uppercase, 1 lowercase, 1 numeric and 1 special character.';
export const UNBOOKMARKED_TEXT = 'Card has been unbookmarked.';
export const BOOKMARKED_TEXT = 'Card has been bookmarked.';
