import axios from 'axios';
import config from './config';
import { OKTA_TOKEN } from './constants';

const setupInterceptors = () => {
  // Add a request interceptor
  axios.interceptors.request.use(
    reConfig => {
      const auth = JSON.parse(localStorage.getItem(OKTA_TOKEN));
      if (auth && auth.idToken && auth.idToken.idToken) {
        reConfig.headers = {
          authorization: auth.idToken.idToken,
          expiration: auth.idToken.expiresAt
        };
      }
      if (reConfig.params && reConfig.params.isOkta) {
        reConfig.baseURL = config.OKTA_BASE_URL;
        reConfig.params = '';
        reConfig.headers.authorization = `SSWS ${config.OKTA_API_KEY}`;
      } else {
        reConfig.baseURL = config.api_url;
      }
      // Do something before request is sent
      return reConfig;
    },
    error =>
      // Do something with request error
      Promise.reject(error)
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    response =>
      // Do something with response data
      response,
    error =>
      // Do something with response error
      Promise.reject(error)
  );
};

export default setupInterceptors;
